import React from 'react';

/**
 * Renders an error message component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Determines whether to show the error message.
 * @param {React.ReactNode} props.children - The content of the error message.
 * @returns {JSX.Element} The error message component.
 */
function ErrorMessage({ children, show = false }) {
  return show ? (
    <span
      role="alert"
      className="absolute italic text-sm text-red-400 font-aw-sans"
    >
      {children}
    </span>
  ) : (
    <></>
  );
}

export default ErrorMessage;
