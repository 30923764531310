import { RetryLink } from '@apollo/client/link/retry';
import { HttpLink } from '@apollo/client/link/http';
import { onError } from '@apollo/client/link/error';
import { ApolloLink } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';

const errorHandler = (msg, error, errorInfo) => {
  // consider logging to an error reporting service here
  console.error(msg, error, errorInfo);
};

/**
 * Returns the Apollo Link for making GraphQL requests.
 * @param {Object} currentUser - The current user object.
 * @returns {ApolloLink} The Apollo Link instance.
 */

const environmentId =
  process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID ?? 'master';
const spaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID ?? 'buvateexvkf2';

export const getLink = (currentUser) => {
  const httpLink = new HttpLink({
    uri: `https://graphql.contentful.com/content/v1/spaces/${spaceId}/environments/${environmentId}?access_token=${currentUser?.user_metadata.contentfulCdnToken}`,
    cache: new InMemoryCache(),
  });
  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 100,
      retryIf: (error, _operation) => !!error,
    },
  });
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
        const msg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
        errorHandler(msg);
        return null;
      });
    }
    if (networkError) {
      const msg = `[Network error]: ${networkError}`;
      errorHandler(msg);
    }
    return;
  });
  const link = ApolloLink.from([errorLink, retryLink, httpLink]);
  return link;
};
