import React, { useEffect, useState } from 'react';
import { P } from '../../../components/typography';
import { useOutletContext } from 'react-router-dom';
import LevelSelectorContext from './LevelSelectorContext';
import LevelSelectorDroppable from './LevelSelectorDroppable';
import LevelSelectorDraggable from './LevelSelectorDraggable';
import { PlusIcon } from '@heroicons/react/24/outline';
import ButtonTertiary from '../../buttons/ButtonTertiary';
import SwatchesIcon from '../../icons/SwatchesIcon';
import { useContentful } from '../../../hooks/useContentful';
import {
  CREATE_HARDWARE_LEVEL,
  CREATE_CABINET_LEVEL,
} from '../../../constants/contentfulActions';
import ErrorMessage from '../../ErrorMessage';
import { LevelDescription } from './LevelDescription';
import { LevelTitle } from './LevelTitle';
import LoaderModal from '../../modals/LoaderModal';
import Speedbump from '../../modals/Speedbump';
import { ALLOWED_CABINET_LEVELS_LIMIT, ALLOWED_HARDWARE_LEVELS_LIMIT } from '../../../graphql/helpers';

// Set Netlify variables (comma separated lists) for sorting products
const maple = process.env.REACT_APP_MAPLE_ORDER;
const cherry = process.env.REACT_APP_CHERRY_ORDER;
const painted = process.env.REACT_APP_PAINTED_ORDER;
const duraform = process.env.REACT_APP_DURAFORM_ORDER;

// Convert Netlify variables to arrays and remove spaces
const mapleOrder = maple ? maple.split(',').map(item => item.trim()) : "";
const cherryOrder = cherry ? cherry.split(',').map(item => item.trim()) : "";
const paintedOrder = painted ? painted.split(',').map(item => item.trim()) : "";
const duraformOrder = duraform ? duraform.split(',').map(item => item.trim()) : "";

const uncategorizedKey = (levelData) => {
  return Object.keys(levelData).filter((key) => {
    return levelData[key].title === 'Uncategorized';
  })[0];
};

const LevelSelector = ({
  selectedIds,
  setSelectedIds,
  levelDataKey,
  itemData,
  forCabinets,
  errors,
}) => {
  const { communityData, setCommunityData } = useOutletContext();
  const [dispatchAddHardwareLevel, addHardwareLevelState] = useContentful();
  const [dispatchAddCabinetLevel, addCabinetLevelState] = useContentful();
  const [levelLimitAlert, setLevelLimitAlert] = useState(false);

  const handleAddHardwareLevel = async () => {
    if (Object.keys(communityData.hardwareLevels).length < ALLOWED_HARDWARE_LEVELS_LIMIT) {
      await dispatchAddHardwareLevel({
        type: CREATE_HARDWARE_LEVEL,
        payload: {
          communityId2: communityData.id,
          levelTitle: `Level ${
            Object.keys(communityData.hardwareLevels).length
          }`,
        },
      });
    } else {
      setLevelLimitAlert(true);
    }
  };

  const handleAddCabinetLevel = async () => {
    if (Object.keys(communityData.cabinetLevels).length < ALLOWED_CABINET_LEVELS_LIMIT) {
      await dispatchAddCabinetLevel({
        type: CREATE_CABINET_LEVEL,
        payload: {
          communityId3: communityData.id,
          levelTitle2: `Level ${
            Object.keys(communityData.cabinetLevels).length
          }`,
        },
      });
    } else {
      setLevelLimitAlert(true);
    }
  };

  const handleDeleteLevel = (deletedId) => {
    let newCommunityData = { ...communityData };
    let deletedLevelData = newCommunityData[levelDataKey][deletedId]?.ids;
    let uncategorizedDataCopy =
      newCommunityData[levelDataKey][
        uncategorizedKey(newCommunityData[levelDataKey])
      ]?.ids;
    uncategorizedDataCopy = uncategorizedDataCopy.concat(deletedLevelData);
    delete newCommunityData[levelDataKey][deletedId];
    newCommunityData[levelDataKey][
      uncategorizedKey(newCommunityData[levelDataKey])
    ].ids = uncategorizedDataCopy;
    setCommunityData(newCommunityData);
  };

  const toggleSelection = (id) => {
    if (
      selectedIds.length &&
      findLevelKey(communityData[levelDataKey], selectedIds[0]) !==
        findLevelKey(communityData[levelDataKey], id)
    ) {
      // multi-selecting from different columns not supported
      return;
    }
    let arrCopy = selectedIds.slice();
    let index = selectedIds.indexOf(id);
    if (index === -1) {
      arrCopy.push(id);
    } else {
      arrCopy.splice(index, 1);
    }
    setSelectedIds(arrCopy);
  };

  const findLevelKey = (dataset, itemId) => {
    let foundKey = undefined;
    Object.keys(dataset).forEach((key) => {
      if (dataset[key]?.ids?.includes(itemId)) {
        foundKey = key;
      }
    });
    return foundKey;
  };

  const multiSelect = (id) => {
    if (!selectedIds.length) {
      return [id];
    }
    const newKey = findLevelKey(communityData[levelDataKey], id);
    const newIdIndex = communityData[levelDataKey][newKey]?.ids.indexOf(id);

    const lastSelected = selectedIds[selectedIds.length - 1];
    const lastSelectedKey = findLevelKey(
      communityData[levelDataKey],
      lastSelected
    );
    const lastSelectedIndex =
      communityData[levelDataKey][lastSelectedKey]?.ids.indexOf(lastSelected);

    // multi selecting to another column
    // select everything up to the index of the current item

    if (newKey !== lastSelectedKey) {
      return communityData[levelDataKey][newKey]?.ids?.slice(0, newIdIndex + 1);
    }

    // multi selecting in same column
    // select everything between last index and current inclusive inclusive

    // nothing to do here
    if (newIdIndex === lastSelectedIndex) {
      return null;
    }

    const isSelectingForwards = newIdIndex > lastSelectedIndex;
    const start = isSelectingForwards ? lastSelectedIndex : newIdIndex;
    const end = isSelectingForwards ? newIdIndex : lastSelectedIndex;

    const inBetween = communityData[levelDataKey][newKey]?.ids?.slice(
      start,
      end + 1
    );

    // everything inbetween needs to have its selection toggled
    // with the exception of the start and end values which will always be selected

    const toAdd = inBetween.filter((id) => {
      if (selectedIds.includes(id)) {
        return false;
      }
      return true;
    });

    const sorted = isSelectingForwards ? toAdd : [...toAdd].reverse();
    const combined = [...selectedIds, ...sorted];

    return combined;
  };

  useEffect(() => {
    if (addCabinetLevelState.success) {
      let communityDataCopy = { ...communityData };
      communityDataCopy.cabinetLevels[addCabinetLevelState.data.sys.id] = {
        title: `Level ${Object.keys(communityDataCopy.cabinetLevels).length}`,
        description: '',
        ids: [],
      };
      setCommunityData(communityDataCopy);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCabinetLevelState.success]);

  useEffect(() => {
    if (addHardwareLevelState.success) {
      let communityDataCopy = { ...communityData };
      communityDataCopy.hardwareLevels[addHardwareLevelState.data.sys.id] = {
        title: `Level ${Object.keys(communityDataCopy.hardwareLevels).length}`,
        description: '',
        ids: [],
      };
      setCommunityData(communityDataCopy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addHardwareLevelState.success]);

  // get product data
  const getItemData = (allItemData, id) => {
    let individualItemData = allItemData?.filter((itemData) => {
      return itemData.sys.id === id;
    });
    return individualItemData ? individualItemData[0] : null;
  };

  const sortCabinets = () => {

    const sortOrders = {
      Maple: mapleOrder,
      Cherry: cherryOrder,
      Painted: paintedOrder,
      Duraform: duraformOrder,
    };

    let communityDataDupe = { ...communityData };
    let levelsNew = communityDataDupe.cabinetLevels;

    Object.entries(levelsNew).forEach(([levelKey, levelValue]) => {
      if (levelValue && 'ids' in levelValue && levelValue.ids.length > 0) {
        let sortedLevelIds = levelValue.ids
          .map((id) => {
            const itemDetails = getItemData(itemData, id);
            return itemDetails ? { ...itemDetails, id } : null;
          })
          .filter((item) => item) // Filter out null values
          .sort((a, b) => {
            // Sorting for known wood types
            if (a.wood in sortOrders && b.wood in sortOrders) {
              if (a.wood === b.wood) {
                return (
                  sortOrders[a.wood].indexOf(a.finish) -
                  sortOrders[b.wood].indexOf(b.finish)
                );
              }
              return a.wood > b.wood ? 1 : -1;
            }
            // Unknown wood types are placed at the end
            if (a.wood in sortOrders) return -1;
            if (b.wood in sortOrders) return 1;
            return 0;
          });

        // At this point the IDs are sorted correctly according to Wood and Finish

        // Update the order in the communityData object
        communityDataDupe.cabinetLevels[levelKey].ids = sortedLevelIds.map(
          (item) => item.id
        );
      }
    });
    setCommunityData(communityDataDupe);
  };

  return !communityData[levelDataKey] ? (
    <></>
  ) : (
    <>
      <LoaderModal
        show={addCabinetLevelState.loading || addHardwareLevelState.loading}
      />
      <div className="pl-2 -translate-y-2">
        <ErrorMessage show={errors.unassignedProducts !== null}>
          {errors.unassignedProducts}
        </ErrorMessage>
      </div>
      {forCabinets && maple && cherry && duraform && painted && (
        <div className="container mx-auto sm:px-6 lg:px-8 mb-4">
          <ButtonTertiary onClick={sortCabinets} className='sortCabinets'>
            Order by Swatch <SwatchesIcon className='orderSwatches ml-1'></SwatchesIcon>
          </ButtonTertiary>
        </div>
      )}
      <LevelSelectorContext
        levelDataKey={levelDataKey}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        findLevelKey={findLevelKey}
      >
        <div className="h-full border-r-2 border-aw-gray-200">
          <LevelSelectorDroppable
            key={uncategorizedKey(communityData[levelDataKey])}
            id={uncategorizedKey(communityData[levelDataKey])}
            selectedIds={selectedIds}
          >
            <div className=" mx-auto">
              <div className="h-[80px] mb-2 border-b w-full px-4">
                <p className="font-aw-sans text-aw-blue-600 font-bold w-full">
                  Uncategorized
                </p>
                <P className="text-sm">
                  Select and drag one or more cards to assign
                </P>
              </div>
            </div>
            <div>
              {communityData[levelDataKey][
                uncategorizedKey(communityData[levelDataKey])
              ]?.ids &&
                communityData[levelDataKey][
                  uncategorizedKey(communityData[levelDataKey])
                ]?.ids.map((id, index) => {
                  return (
                    <LevelSelectorDraggable
                      parentKey={uncategorizedKey(communityData[levelDataKey])}
                      selected={selectedIds.includes(id)}
                      multiSelect={multiSelect}
                      toggleSelection={toggleSelection}
                      setSelectedIds={setSelectedIds}
                      key={id}
                      id={id}
                      index={index}
                      itemData={itemData}
                      selectedIds={selectedIds}
                    />
                  );
                })}
            </div>
          </LevelSelectorDroppable>
        </div>
        <div className="flex flex-nowrap overflow-x-auto">
          {Object.keys(communityData[levelDataKey]).map((key) => {
            return (
              <React.Fragment key={key}>
                {communityData[levelDataKey][key].title !== 'Uncategorized' && (
                  <LevelSelectorDroppable key={key} id={key}>
                    <div className={`border-r-2 border-aw-gray-200 h-full`}>
                      <div className="h-[80px] mb-2 border-b">
                        <div className="w-11/12 mx-auto">
                          <LevelTitle
                            levelId={key}
                            levelDataKey={levelDataKey}
                            handleDeleteLevel={handleDeleteLevel}
                          />
                        </div>
                        <div className="w-11/12 mx-auto">
                          <LevelDescription
                            levelId={key}
                            levelDataKey={levelDataKey}
                          />
                        </div>
                      </div>
                      {communityData[levelDataKey][key]?.ids &&
                        communityData[levelDataKey][key]?.ids.map(
                          (id, index) => {
                            return (
                              <LevelSelectorDraggable
                                parentKey={key}
                                selected={selectedIds.includes(id)}
                                multiSelect={multiSelect}
                                toggleSelection={toggleSelection}
                                setSelectedIds={setSelectedIds}
                                key={id}
                                id={id}
                                index={index}
                                itemData={itemData}
                                selectedIds={selectedIds}
                              />
                            );
                          }
                        )}
                    </div>
                  </LevelSelectorDroppable>
                )}
              </React.Fragment>
            );
          })}
          <div className="w-auto">
            <button
              className="w-auto p-2 mx-4 shadow-md mt-1 rounded bg-aw-blue-400 flex items-center justify-between"
              onClick={
                forCabinets ? handleAddCabinetLevel : handleAddHardwareLevel
              }
            >
              <div className="px-2 text-white font-aw-sans font-bold">Add</div>
              <div>
                <PlusIcon className="w-7 h-7 text-white" />
              </div>
            </button>
          </div>
        </div>
        {/* TODO: Remove or edit this speedbump and corresponding logic when the level limits are increased */}
        <Speedbump
          warning={true}
          open={levelLimitAlert}
          setOpen={setLevelLimitAlert}
          onConfirm={() => setLevelLimitAlert(false)}
          onClose={() => setLevelLimitAlert(false)}
          confirmBtnText="OK"
          confirmBody={`Please limit your microsite to ${
            forCabinets ? ALLOWED_CABINET_LEVELS_LIMIT : ALLOWED_HARDWARE_LEVELS_LIMIT
          } or fewer levels for ${forCabinets ? 'cabinets' : 'hardware'}.`}
        />
      </LevelSelectorContext>
    </>
  );
};

export default LevelSelector;
