import {
  CheckIcon,
  PencilSquareIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Speedbump from '../../modals/Speedbump';

export const LevelTitle = ({ levelId, handleDeleteLevel, levelDataKey }) => {
  const { communityData, setCommunityData } = useOutletContext();
  const [editingMode, setEditingMode] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [inputValue, setInputValue] = useState(
    communityData[levelDataKey][levelId].title
  );

  if (!communityData[levelDataKey]) {
    return;
  }

  const toggleEditingMode = () => {
    let editingModeCopy = editingMode;
    setEditingMode(!editingModeCopy);
  };

  const submit = () => {
    if (!inputValue.length || inputValue === 'Uncategorized') {
      return;
    }
    let newCommunityData = { ...communityData };
    newCommunityData[levelDataKey][levelId].title = inputValue;
    setCommunityData(newCommunityData);
    toggleEditingMode();
  };

  const cancel = () => {
    setInputValue(communityData[levelDataKey][levelId].title);
    toggleEditingMode();
  };

  const onChange = (event) => {
    setInputValue(event.target.value);
  };

  if (editingMode) {
    return (
      <div className="flex justify-between items-center">
        <div className="w-2/3">
          <input
            type="text"
            name={`title-${levelId}`}
            id={`title-${levelId}`}
            value={inputValue}
            className={`font-aw-sans block text-base w-full rounded-md border-2 py-1.5ring-1 ring-inset focus:ring-2 focus:ring-inset sm:leading-6 ${
              !inputValue.length
                ? 'pr-10 text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                : 'text-aw-blue-900 shadow-sm ring-aw-blue-900 placeholder:text-gray-400 focus:ring-aw-blue-400'
            }`}
            placeholder="Enter a level title"
            onChange={onChange}
          />
          {!inputValue.length && (
            <p
              className="mt-2 text-sm text-red-600"
              id={`error-empty-${levelId}`}
            >
              Please enter a level title.
            </p>
          )}
          {inputValue === 'Uncategorized' && (
            <p
              className="mt-2 text-sm text-red-600"
              id={`error-uncategorized-${levelId}`}
            >
              Please enter a different level title.
            </p>
          )}
        </div>
        <div>
          <button className="w-8 h-8" onClick={submit}>
            <CheckIcon />
          </button>
          <button className="w-8 h-8" onClick={cancel}>
            <XMarkIcon />
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="flex justify-between items-center">
      <div className="pl-2">
        <p className="font-aw-sans text-aw-blue-600 font-bold">
          {communityData[levelDataKey][levelId].title}
        </p>
      </div>
      <div>
        <button className="w-8 h-8" onClick={toggleEditingMode}>
          <PencilSquareIcon />
        </button>
        <button
          className="w-8 h-8"
          onClick={() => {
            setConfirmDeleteOpen(true);
          }}
        >
          <TrashIcon />
        </button>
        <Speedbump
          warning={true}
          open={confirmDeleteOpen}
          setOpen={setConfirmDeleteOpen}
          onConfirm={() => {
            handleDeleteLevel(levelId);
            setConfirmDeleteOpen(false);
          }}
          onClose={() => setConfirmDeleteOpen(false)}
          confirmBtnText="Delete"
          confirmBody="Are you sure you want to delete this level?"
        />
      </div>
    </div>
  );
};
