import { useState, useRef } from 'react';
import { useQuery, gql } from '@apollo/client';
import { HARDWARE_LEVELS_QUERY_LIMIT, PRODUCTS_COLLECTION_LIMIT } from '../helpers';

export function useCommunityHardwareLevelsQuery(id, onUpdate) {
  const [formattedData, setFormattedData] = useState({});
  const perPage = useRef(HARDWARE_LEVELS_QUERY_LIMIT);
  const lastLoadSkip = useRef(null);
  const retries = useRef(0);
  const maxRetries = useRef(3);

  const formatData = (data) => {
    const { community } = data;

    const { hardwareLevelsCollection } = community;

    let hardwareLevelsObj = {};
    hardwareLevelsCollection?.items?.forEach((hardwareLevel) => {
      hardwareLevelsObj[hardwareLevel.sys.id] = {
        title: hardwareLevel?.title || '',
        description: hardwareLevel?.description || '',
        ids: hardwareLevel.productsCollection.items.map((product) => {
          return product.sys.id;
        }),
      };
    });
    return {
      hardwareLevels: Object.keys(hardwareLevelsObj).length
        ? hardwareLevelsObj
        : {},
    };
  };

  const GET_COMMUNITY_HARDWARE_LEVELS = gql`
    query GetSingleCommunityHardwareLevels(
      $id: String!
      $skip: Int
    ) {
      community(id: $id) {
        hardwareLevelsCollection(limit: ${perPage.current}, skip: $skip) {
          total
          items {
            title
            description
            productsCollection(limit: ${PRODUCTS_COLLECTION_LIMIT}) {
              items {
                sys {
                  id
                }
              }
            }
            sys {
              id
            }
          }
        }
      }
    }
  `;

  const { loading, error, data, fetchMore } = useQuery(
    GET_COMMUNITY_HARDWARE_LEVELS,
    {
      variables: { id, skip: 0 },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      onCompleted,
    }
  );

  function onCompleted(rawData) {
    const formattedData = formatData(rawData);
    setFormattedData(formattedData);
    if(onUpdate) {
      onUpdate(formattedData, rawData);
    }
    requestAdditionalPages(rawData);
  }

  function requestAdditionalPages(rawData) {
    const loaded = rawData?.community?.hardwareLevelsCollection?.items?.length;
    const total = rawData?.community?.hardwareLevelsCollection?.total;

    const isRetry = lastLoadSkip.current === loaded;
    if(!isRetry) {
      retries.current = 0;
    }
    
    const hitRetryLimit = retries.current >= maxRetries.current
    if(hitRetryLimit || (loaded >= total)) {
      return;
    }

    lastLoadSkip.current = loaded;
    retries.current += 1;
    fetchMore({
      variables: {
        skip: loaded,
      },
    });
  }

  return { data, formattedData, loading, error };
}
