import React, { useState, Fragment, useCallback } from 'react';
import ButtonTertiary from '../buttons/ButtonTertiary';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useOutletContext } from 'react-router';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import GeneratorAccordionItem from './GeneratorAccordionItem';
import Speedbump from '../modals/Speedbump';

export function belongsToHardware(productsList, id) {
  const hardwareData = productsList.filter((productFamily) => {
    return productFamily.name === 'hardware';
  })[0];
  let belongs = false;
  hardwareData.productLines.forEach((productLine) => {
    productLine.products.forEach((product) => {
      if (id === product.id) {
        belongs = true;
      }
    });
  });
  return belongs;
}

export function existsElsewhere(communityData, currentIndex, id) {
  let exists = false;
  communityData.rooms.forEach((room, index) => {
    if (index !== currentIndex) {
      room.products.forEach((selection) => {
        if (selection === id) {
          exists = true;
        }
      });
    }
  });
  return exists;
}

function GeneratorRoomBuilder({ currentRoom, index }) {
  const [open, setOpen] = useState(false);
  const [confirmResetOpen, setConfirmResetOpen] = useState(false);
  const [targetedRoom, setTargetedRoom] = useState(null);
  const {
    communityData,
    setCommunityData,
    productsList,
    setRemovedRecommendations,
  } = useOutletContext();
  const [activeIndex, setActiveIndex] = useState(-1);
  const [tempSelectedRoom, setTempSelectedRoom] = useState(null);
  const [confirmAddSelectionsOpen, setConfirmAddSelectionsOpen] =
    useState(false);
  // set the desired order of accordion items here. If the a product family is not in this list, it will go to the end
  const productOrder = [
    'portfolioSelect',
    'portfolio',
    'valueBuilt',
    'hardware',
  ];

  const allProductIdsByFamily = productsList.reduce((acc, productFamily) => {
    const productIds = productFamily.productLines.reduce((acc, productLine) => {
      const productIds = productLine.products.map((product) => product.id);
      return [...acc, ...productIds];
    }, []);
    return { ...acc, [productFamily.name]: productIds };
  }, {});

  const indexInCommunityData = communityData.rooms.findIndex(
    (room) => room.id === currentRoom.id
  );

  const handleResetSelections = () => {
    // if we are resetting a room’s selections, we need to check to see if the item exists existsElsewhere
    // and purge from unique selections + level data if so
    let foundRecommendations = [];

    communityData.rooms[index].products.forEach((itemId) => {
      let newCabinetLevelData = communityData.cabinetLevels;
      let newHardwareLevelData = communityData.hardwareLevels;
      let newUniqueCabinetSelections = communityData.uniqueCabinetSelections;
      let newUniqueHardwareSelections = communityData.uniqueHardwareSelections;
      if (!existsElsewhere(communityData, index, itemId)) {
        const foundRecommendation = communityData.recommendations.find(
          (recommendation) => recommendation.product.id === itemId
        );
        if (foundRecommendation) {
          foundRecommendations.push(foundRecommendation);
          setRemovedRecommendations((removedRecommendations) => {
            return [...removedRecommendations, foundRecommendation];
          });
        }
        if (belongsToHardware(productsList, itemId)) {
          // configure new hardware level data w id removed
          let removalKey = '';
          Object.keys(communityData.hardwareLevels).forEach((key) => {
            communityData.hardwareLevels[key].ids.forEach((id) => {
              if (id === itemId) {
                removalKey = key;
              }
            });
          });
          newHardwareLevelData[removalKey] = {
            title: communityData.hardwareLevels[removalKey].title,
            description: communityData.hardwareLevels[removalKey].description,
            ids: communityData.hardwareLevels[removalKey].ids.filter((id) => {
              return id !== itemId;
            }),
          };

          // configure new hardware selections array w id removed
          let selectionIndex = newUniqueHardwareSelections.indexOf(itemId);
          newUniqueHardwareSelections.splice(selectionIndex, 1);
        } else {
          let removalKey = '';
          // configure new cabinet level data w id removed
          Object.keys(communityData.cabinetLevels).forEach((key) => {
            communityData.cabinetLevels[key].ids.forEach((id) => {
              if (id === itemId) {
                removalKey = key;
              }
            });
          });
          newCabinetLevelData[removalKey] = {
            title: communityData.cabinetLevels[removalKey].title,
            description: communityData.cabinetLevels[removalKey].description,
            ids: communityData.cabinetLevels[removalKey].ids.filter((id) => {
              return id !== itemId;
            }),
          };

          // configure new cabinet selections array w id removed
          let selectionIndex = newUniqueCabinetSelections.indexOf(itemId);
          newUniqueCabinetSelections.splice(selectionIndex, 1);
        }
      }

      const newRecommendations = [...communityData.recommendations].filter(
        (recommendation) => {
          return !foundRecommendations.find(
            (foundRecommendation) =>
              foundRecommendation.product.id === recommendation.product.id
          );
        }
      );
      setCommunityData({
        ...communityData,
        uniqueCabinetSelections: newUniqueCabinetSelections,
        uniqueHardwareSelections: newUniqueHardwareSelections,
        cabinetLevels: newCabinetLevelData,
        hardwareLevels: newHardwareLevelData,
        recommendations: newRecommendations,
      });
    });

    // find any room that has this room's id set to mirrored, set mirrored to null on it, and remove all selections
    // remove all selections from this room as well
    communityData.rooms.forEach((room, index) => {
      if (room.mirroring === currentRoom.id) {
        const newCommunityData = [...communityData.rooms];
        newCommunityData[index].mirroring = null;
        setCommunityData({ ...communityData, rooms: newCommunityData });
      }
    });
    setCommunityData({
      ...communityData,
      rooms: [
        ...communityData.rooms.slice(0, indexInCommunityData),
        {
          ...currentRoom,
          products: [],
          mirroring: null,
        },
        ...communityData.rooms.slice(indexInCommunityData + 1),
      ],
    });

    setTargetedRoom(null);
    setConfirmResetOpen(false);
  };

  const handleItemClick = useCallback(
    (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    },
    [activeIndex]
  );

  const handleAddSelectionsFrom = useCallback(
    (targetRoom) => {
      setCommunityData({
        ...communityData,
        rooms: [
          ...communityData.rooms.slice(0, index),
          {
            ...currentRoom,
            products: targetRoom.products,
            mirroring: targetRoom.id,
          },
          ...communityData.rooms.slice(index + 1),
        ],
      });
      setTargetedRoom(targetRoom);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmAddSelectionsOpen]
  );
  const getRoomTitle = (room) =>
    room?.name ? room.name : `Room ${communityData.rooms.indexOf(room) + 1}`;

  return (
    <>
      <ButtonTertiary
        onClick={() => setOpen(true)}
        className="w-full justify-center"
      >
        Define room options
      </ButtonTertiary>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-7xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="font-aw-serif text-3xl font-semibold leading-6 text-aw-blue-600">
                            Define{' '}
                            {currentRoom.name
                              ? currentRoom.name
                              : `Room ${index + 1}`}{' '}
                            offerings for {communityData.name}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6 pb-36">
                        <div className="flex justify-between">
                          {communityData.rooms.length > 1 &&
                            communityData.rooms.some((room) => {
                              if (
                                room.id === currentRoom.id ||
                                room.mirroring
                              ) {
                                return false;
                              }

                              return !!room.products.length;
                            }) && (
                              <div className="flex items-center mb-2">
                                <p className="mr-2 font-aw-serif">
                                  {targetedRoom
                                    ? 'Mirroring selections from:'
                                    : 'Add selections from:'}
                                </p>
                                <div className="flex">
                                  {targetedRoom ? (
                                    <>
                                      <span
                                        className="font-aw-sans font-bold cursor-pointer text-aw-blue-600 hover:text-aw-blue-700"
                                        onClick={() => {
                                          setCommunityData({
                                            ...communityData,
                                            rooms: [
                                              ...communityData.rooms.slice(
                                                0,
                                                index
                                              ),
                                              {
                                                ...currentRoom,
                                                mirroring: null,
                                              },
                                              ...communityData.rooms.slice(
                                                index + 1
                                              ),
                                            ],
                                          });

                                          setTargetedRoom(null);
                                        }}
                                      >
                                        {getRoomTitle(targetedRoom)}
                                      </span>
                                      <span className="font-aw-sans text-aw-gray-700 ml-3 italic">
                                        (click to remove)
                                      </span>
                                    </>
                                  ) : (
                                    communityData.rooms.map((room) => {
                                      if (
                                        room.id !== currentRoom.id &&
                                        room.products.length &&
                                        !room.mirroring
                                      ) {
                                        const isTargetedRoom =
                                          room.id === targetedRoom?.id;
                                        return (
                                          <div
                                            key={room.id}
                                            className="relative flex flex-col items-center"
                                          >
                                            <button
                                              onClick={() => {
                                                setTempSelectedRoom(room);
                                                setConfirmAddSelectionsOpen(
                                                  true
                                                );
                                              }}
                                              className={`ml-2 rounded-md bg-aw-blue-600 px-2 py-1 text-white font-aw-sans font-medium hover:bg-aw-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-aw-blue-600 ${
                                                isTargetedRoom
                                                  ? 'opacity-50'
                                                  : ''
                                              } `}
                                              disabled={isTargetedRoom}
                                            >
                                              {getRoomTitle(room)}
                                            </button>
                                          </div>
                                        );
                                      } else return null;
                                    })
                                  )}
                                </div>
                              </div>
                            )}
                          {!communityData.rooms[indexInCommunityData]
                            .mirroring && (
                            <div
                              className="ml-auto cursor-pointer"
                              onClick={() => setConfirmResetOpen(true)}
                            >
                              <span className="font-aw-sans text-base text-aw-blue-400">
                                Clear all selections
                              </span>
                            </div>
                          )}
                        </div>
                        <p className="mt-2 font-aw-serif">
                          Click a swatch to add or remove
                        </p>
                        <div>
                          <div className="mt-6 w-full">
                            {[...productsList]
                              .sort((a, b) => {
                                const indexA = productOrder.indexOf(a.name);
                                const indexB = productOrder.indexOf(b.name);
                                if (indexA === -1 || indexB === -1) {
                                  return 0;
                                }

                                if (indexA < indexB) {
                                  return -1;
                                } else if (indexA > indexB) {
                                  return 1;
                                } else {
                                  return 0;
                                }
                              })
                              ?.map((item, index) => {
                                const currentProductIds =
                                  allProductIdsByFamily[item.name];
                                // count how many ids from currentProductIds are in currentRoom.products

                                const currentSelectionCount =
                                  currentRoom.products?.filter(
                                    (selection) =>
                                      currentProductIds.indexOf(selection) !==
                                      -1
                                  ).length;

                                const hasProducts = item.productLines.some(
                                  (product) => {
                                    return product.products.length > 0;
                                  }
                                );
                                //  this is to ensure that only product lines with products linked to them are shown
                                return hasProducts ? (
                                  <div
                                    key={item.name}
                                    className="mb-2 rounded border-t"
                                  >
                                    <div
                                      className="flex cursor-pointer items-center justify-between py-2 px-4"
                                      onClick={() => handleItemClick(index)}
                                    >
                                      <div className="flex h-16 items-center justify-start">
                                        <span className="font-aw-serif text-2xl text-aw-blue-600">
                                          {item.formattedName}
                                        </span>

                                        {currentSelectionCount > 0 && (
                                          <div className="ml-4 flex h-8 w-8 items-center justify-center rounded-full bg-aw-gray-200">
                                            <span className="font-aw-sans text-sm text-aw-blue-600">
                                              {currentSelectionCount}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                      <ChevronDownIcon
                                        className={`h-6 w-6 ${
                                          activeIndex === index
                                            ? 'rotate-180 transform'
                                            : ''
                                        }`}
                                      />
                                    </div>
                                    {activeIndex === index && (
                                      <div className="px-4 pb-2">
                                        <GeneratorAccordionItem
                                          productFamily={item}
                                          disabled={
                                            !!communityData.rooms[
                                              indexInCommunityData
                                            ].mirroring
                                          }
                                          indexInCommunityData={
                                            indexInCommunityData
                                          }
                                          currentSelections={
                                            communityData.rooms[
                                              indexInCommunityData
                                            ].products
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <Fragment key={item.name}></Fragment>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      <div className="fixed bottom-0 flex h-24 w-full items-center justify-center border-t bg-white z-10">
                        <ButtonTertiary
                          className="w-44 justify-center"
                          onClick={() => setOpen(false)}
                        >
                          Done
                        </ButtonTertiary>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Speedbump
        open={confirmResetOpen}
        setOpen={setConfirmResetOpen}
        onConfirm={() => handleResetSelections()}
        onClose={() => setConfirmResetOpen(false)}
        confirmBtnText="Clear all selections"
        confirmTitle="Clear all selections?"
        confirmBody={
          <>
            <span>
              Are you sure you want to clear all selections? This action cannot
              be undone.
            </span>{' '}
            <span className="block mt-4 italic">
              (note: This will also remove any recommendations for products that
              are not selected in any other room.)
            </span>
          </>
        }
      />
      <Speedbump
        warning={false}
        open={confirmAddSelectionsOpen}
        setOpen={setConfirmAddSelectionsOpen}
        onConfirm={() => {
          handleAddSelectionsFrom(tempSelectedRoom);
          setConfirmAddSelectionsOpen(false);
        }}
        onClose={() => setConfirmAddSelectionsOpen(false)}
        confirmBtnText="Add selections"
        confirmBody={`Are you sure you want to add selections from ${
          tempSelectedRoom ? `${getRoomTitle(tempSelectedRoom)}` : ''
        }? This will replace all current selections for this room.`}
        confirmTitle="Add selections?"
      />
    </>
  );
}

export default GeneratorRoomBuilder;
