export default function TextInput({
  label = '',
  value,
  placeholder,
  id = '',
  type = 'text',
  name = '',
  onChange = () => {},
  notes = '',
  additionalInputClassNames = '',
}) {
  const styles = [
    'block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm h-[55px]',
    additionalInputClassNames,
  ].join(' ');

  return (
    <div>
      {label ? (
        <>
          <label
            htmlFor={id}
            className="block text-md font-aw-sans text-gray-700"
          >
            {label}
            <span className="text-xs text-aw-gray-300 ml-4">{notes}</span>
          </label>
        </>
      ) : (
        <></>
      )}
      <div className="mt-4">
        <input
          type={type}
          name={name}
          id={id}
          value={value}
          className={styles}
          placeholder={placeholder}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
