import slugify from 'slugify';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

export const ROOMS_QUERY_LIMIT = 9;
export const ALLOWED_ROOMS_LIMIT = 9;
export const PRODUCTS_COLLECTION_LIMIT = 600;
export const CABINET_LEVELS_QUERY_LIMIT = 18;
export const ALLOWED_CABINET_LEVELS_LIMIT = 18;
export const HARDWARE_LEVELS_QUERY_LIMIT = 18;
export const ALLOWED_HARDWARE_LEVELS_LIMIT = 18;

export const extractImagesData = (data) => {
  const images = [];

  data?.assetCollection?.items.forEach((item) => {
    images.push({
      id: item.sys.id,
      url: item.url,
      fileName: item.fileName,
      title: item.title,
    });
  });
  return { total: data?.assetCollection?.total, images };
};

/*
 * This helper function takes the response from Contentful,
 * manipulates it into something usable by the app,
 * and also performs synchronization and sanitization tasks to heal malformed data.
 *
 * NOTE: after the community query was split up to load rooms, cabinet levels, and
 * hardware levels in separate, potentially paginated queries, this one handles
 * the top level community data.
 *  - sanitization happens in src/graphql/query-hooks for each data type
 *  - synchronization and sanitization also happens once the full community is loaded
 *    in Generator.jsx where useEffect watches isCommunityLoaded
 *
 * */
export const extractSingleCommunitiesData = (data) => {
  const { community } = data;

  const { banner, name, builder, recommendationsCollection } = community;
  const bannerObj = {
    fileName: banner?.fileName || null,
    url: banner?.url || null,
    id: banner?.sys?.id || null,
  };

  const builderObj = {
    name: builder?.name || null,
    id: builder?.sys?.id || null,
    logo: builder?.logo?.url || null,
    url: builder?.url || null,
    builderTag: builder?.builderTag || null,
  };

  const addOns = community.addonsCollection?.items?.map((addOn) => {
    const image = addOn.image || {};

    return {
      id: addOn.sys?.id ?? null,
      description: addOn.description || '',
      title: addOn.title || '',
      videoUrl: addOn.videoUrl || '',
      image: {
        id: image.sys?.id || null,
        url: image.url || null,
      },
    };
  });

  const recommendations = recommendationsCollection?.items?.map(
    (recommendation) => {
      const image = recommendation.image || {};
      const product = recommendation.product || {};

      return {
        id: recommendation.sys?.id ?? null,
        image: {
          id: image.sys?.id || null,
          url: image.url || null,
        },
        product: {
          id: product.sys?.id || null,
        },
      };
    }
  );

  return {
    name: name || '',
    banner: bannerObj,
    builder: builderObj || {},
    recommendations: recommendations || [],
    addOns: addOns || [],
    previewUrl: community.previewUrl || null,
    publishedUrl: community.publishedUrl || null,
    publishedAt: community.sys.publishedAt || null,
    isPublished: community.publishedStatus,
  };
};

export const synchronizeOrphanedLevelsToUncategorized = (
  uniqueHardwareSelections,
  communityHardwareLevelData,
  uniqueCabinetSelections,
  communityCabinetLevelData
) => {
  let hardwareExpectedInLevels = uniqueHardwareSelections.slice();
  let hardwareLevelsObj = {};
  communityHardwareLevelData?.community?.hardwareLevelsCollection?.items?.forEach(
    (hardwareLevel) => {
      // sanitize by filtering duplicate ids
      // and scrubbing out products that are not a part of any room
      let filteredHardwareLevelIds = [];
      hardwareLevel.productsCollection.items.forEach((product) => {
        if (
          !filteredHardwareLevelIds.includes(product.sys.id) &&
          hardwareExpectedInLevels.includes(product.sys.id)
        ) {
          filteredHardwareLevelIds.push(product.sys.id);
          hardwareExpectedInLevels.splice(
            hardwareExpectedInLevels.indexOf(product.sys.id),
            1
          );
        }
      });
      hardwareLevelsObj[hardwareLevel.sys.id] = {
        title: hardwareLevel?.title || '',
        description: hardwareLevel?.description || '',
        ids: filteredHardwareLevelIds,
      };
    }
  );
  // leftover items exist in rooms, but not levels
  // add to uncategorized
  if (hardwareExpectedInLevels.length) {
    communityHardwareLevelData?.community?.hardwareLevelsCollection?.items?.forEach(
      (hardwareLevel) => {
        if (hardwareLevel?.title === 'Uncategorized') {
          hardwareLevelsObj[hardwareLevel.sys.id].ids = hardwareLevelsObj[
            hardwareLevel.sys.id
          ].ids.concat(hardwareExpectedInLevels);
        }
      }
    );
  }

  let cabinetsExpectedInLevels = uniqueCabinetSelections.slice();
  let cabinetLevelsObj = {};
  communityCabinetLevelData?.community?.cabinetLevelsCollection?.items?.forEach(
    (cabinetLevel) => {
      // sanitize by filtering duplicate ids
      // and scrubbing out products that are not a part of any room
      let filteredCabinetLevelIds = [];
      cabinetLevel.productsCollection.items.forEach((product) => {
        if (
          !filteredCabinetLevelIds.includes(product.sys.id) &&
          cabinetsExpectedInLevels.includes(product.sys.id)
        ) {
          filteredCabinetLevelIds.push(product.sys.id);
          cabinetsExpectedInLevels.splice(
            cabinetsExpectedInLevels.indexOf(product.sys.id),
            1
          );
        }
      });
      cabinetLevelsObj[cabinetLevel.sys.id] = {
        title: cabinetLevel?.title || '',
        description: cabinetLevel?.description || '',
        ids: filteredCabinetLevelIds,
      };
    }
  );
  // leftover items exist in rooms, but not levels
  // add to uncategorized
  if (cabinetsExpectedInLevels.length) {
    communityCabinetLevelData?.community?.cabinetLevelsCollection?.items?.forEach(
      (cabinetLevel) => {
        if (cabinetLevel?.title === 'Uncategorized') {
          cabinetLevelsObj[cabinetLevel.sys.id].ids = cabinetLevelsObj[
            cabinetLevel.sys.id
          ].ids.concat(cabinetsExpectedInLevels);
        }
      }
    );
  }

  return {
    cabinetLevelsObj,
    hardwareLevelsObj,
  };
};

export const extractAllCommunitiesData = (data) => {
  const communities = [];

  data.userCollection.items.forEach(({ communitiesCollection }) => {
    communitiesCollection.items.forEach((community) => {
      const banner = community.banner || {};

      const builder = community.builder || {};
      const builderObj = {
        name: builder.name || null,
        id: builder?.sys?.id || null,
        logo: builder.logo?.url || null,
        url: builder.url || null,
      };

      communities.push({
        name: community.name || '',
        publishedStatus: community.publishedStatus,
        url: community.publishedUrl || null,
        id: community.sys.id,
        builder: builderObj,
        hasBeenPublished: community.previewUrl !== null,
        banner: {
          fileName: banner.fileName || null,
          src: banner.url || null,
          id: banner.sys?.id || null,
        },
      });
    });
  });

  const sortedCommunities = communities.sort((a, b) => {
    const lowerA = a.name.toLowerCase();
    const lowerB = b.name.toLowerCase();

    if (lowerA < lowerB) {
      return -1;
    }
    if (lowerA > lowerB) {
      return 1;
    }
    return 0;
  });

  return sortedCommunities;
};

export const extractBuildersData = (data) => {
  const allBuilders = [];
  const recentBuilders = [];

  data.builderCollection?.items.forEach(
    ({ name, url, sys, logo, builderTag }) => {
      allBuilders.push({
        name: name || null,
        url: url || null,
        id: sys?.id || null,
        logo: logo?.url || null,
        builderTag: builderTag || null,
      });
    }
  );

  data.userCollection?.items.forEach(({ recentBuildersCollection }) => {
    recentBuildersCollection.items.forEach(({ sys }) => {
      recentBuilders.push(sys?.id || null);
    });
  });

  return { allBuilders, recentBuilders };
};

export const extractCommunityNamesFromBuilders = (data) => {
  const names = [];

  if (data && data.communityCollection && data.communityCollection.items) {
    data.communityCollection.items.forEach((item) => {
      if (item && item.name) {
        names.push(item.name);
      }
    });
  }

  return names;
};

export const checkCommunityNameUnique = (name, list, initialCommunityName) => {
  name = name.trim();
  if (name === '' || !name) return false;
  const slugifiedInput = slugify(name, { lower: true, strict: true });
  if (initialCommunityName) {
    const slugifiedInitialCommunityName = slugify(initialCommunityName, {
      lower: true,
      strict: true,
    });
    if (slugifiedInput === slugifiedInitialCommunityName) return true;
  }
  return !list.some(
    (communityName) =>
      slugify(communityName, { lower: true, strict: true }) === slugifiedInput
  );
};

const getMarks = (element) => {
  const marks = [];

  let currentNode = element;
  while (currentNode.parentElement) {
    const { tagName } = currentNode;

    if (tagName) {
      const tag = tagName.toLowerCase();

      switch (tag) {
        case 'strong':
        case 'b':
          marks.push({ type: MARKS.BOLD });
          break;
        case 'em':
        case 'i':
          marks.push({ type: MARKS.ITALIC });
          break;
        case 'u':
          marks.push({ type: MARKS.UNDERLINE });
          break;
        default:
          break;
      }
    }

    currentNode = currentNode.parentElement;
  }

  return marks;
};

const processNode = (node, content = []) => {
  Array.from(node.childNodes).forEach((childNode) => {
    const { tagName, nodeType, nodeValue } = childNode;

    if (nodeType === Node.TEXT_NODE) {
      const marks = getMarks(childNode.parentElement);
      const inlineElement = childNode.parentElement.closest('a');

      if (inlineElement) {
        content.push({
          nodeType: 'hyperlink',
          data: { uri: inlineElement.href },
          content: [
            {
              nodeType: 'text',
              value: nodeValue,
              marks: marks,
              data: {},
            },
          ],
        });
      } else {
        content.push({
          nodeType: 'text',
          value: nodeValue,
          marks: marks,
          data: {},
        });
      }
    } else if (nodeType === Node.ELEMENT_NODE) {
      const tag = tagName.toLowerCase();
      if (tag === 'a') {
        processNode(childNode, content);
      } else {
        let blockType;

        switch (tag) {
          case 'h1':
            blockType = BLOCKS.HEADING_1;
            break;
          case 'h2':
            blockType = BLOCKS.HEADING_2;
            break;
          case 'h3':
            blockType = BLOCKS.HEADING_3;
            break;
          case 'h4':
            blockType = BLOCKS.HEADING_4;
            break;
          case 'h5':
            blockType = BLOCKS.HEADING_5;
            break;
          case 'h6':
            blockType = BLOCKS.HEADING_6;
            break;
          case 'p':
            blockType = BLOCKS.PARAGRAPH;
            break;
          case 'ol':
            blockType = BLOCKS.OL_LIST;
            break;
          case 'ul':
            blockType = BLOCKS.UL_LIST;
            break;
          case 'li':
            blockType = BLOCKS.LIST_ITEM;
            break;
          default:
            break;
        }

        if (blockType) {
          const contentfulNode = {
            nodeType: blockType,
            data: {},
            content: [],
          };

          if (blockType === BLOCKS.LIST_ITEM) {
            contentfulNode.content.push({
              nodeType: BLOCKS.PARAGRAPH,
              data: {},
              content: [],
            });
            processNode(childNode, contentfulNode.content[0].content);
          } else {
            processNode(childNode, contentfulNode.content);
          }

          if (
            blockType === BLOCKS.ORDERED_LIST ||
            blockType === BLOCKS.UNORDERED_LIST
          ) {
            if (
              content.length > 0 &&
              content[content.length - 1].nodeType === blockType
            ) {
              content[content.length - 1].content.push(contentfulNode);
            } else {
              content.push(contentfulNode);
            }
          } else if (
            blockType === BLOCKS.LIST_ITEM &&
            content.length > 0 &&
            (content[content.length - 1].nodeType === BLOCKS.ORDERED_LIST ||
              content[content.length - 1].nodeType === BLOCKS.UNORDERED_LIST)
          ) {
            content[content.length - 1].content.push(contentfulNode);
          } else {
            content.push(contentfulNode);
          }
        } else {
          processNode(childNode, content);
        }
      }
    }
  });
};

export const htmlToContentfulRichText = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const content = [];
  const contentfulRichText = {
    nodeType: BLOCKS.DOCUMENT,
    data: {},
    content,
  };

  processNode(doc.body, content);

  return contentfulRichText;
};
