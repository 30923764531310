import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ButtonSecondary from '../buttons/ButtonSecondary';
import GeneratorBuilderCard from './GeneratorBuilderCard';

function GeneratorSelectBuilder({allBuildersData={}, setBuilder}) {
  const [open, setOpen] = useState(false);

const recentBuildersData = allBuildersData.allBuilders
  ? allBuildersData.allBuilders.filter((builder) =>
      allBuildersData.recentBuilders.includes(builder.id)
    )
  : [];

  const sortedBuilders = allBuildersData.allBuilders || [];
  // sort the data alphabetically by company name
  // and split into an array for each letter
const allBuildersSorted = sortedBuilders
  ?.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  })
  .reduce((acc, cur) => {
    const firstLetter = cur.name.charAt(0).toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(cur);
    return acc;
  }, {});

  const allBuildersSortedArray = Object.entries(allBuildersSorted);
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  for (let i = 0; i < alphabet.length; i++) {
    const letter = alphabet[i];
    if (!allBuildersSorted[letter]) {
      allBuildersSortedArray.push([letter, []]);
    }
  }

  return (
    <div>
      <ButtonSecondary
        className="mt-4 w-full justify-center"
        onClick={() => setOpen(true)}
      >
        Browse builders
      </ButtonSecondary>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100 translate-y-0 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 scale-100"
                leaveTo="opacity-0"
              >
                <Dialog.Panel className="shadow-xltransition-all relative h-[75vh] max-w-[90%] transform overflow-y-scroll rounded-lg text-left bg-aw-gray-100 sm:w-full sm:p-6">
                  <div className="pt-4 px-6 bg-aw-gray-100">
                    <Dialog.Title
                      as="h3"
                      className="text-3xl py-4 leading-6 text-aw-blue-600 font-aw-serif font-bold"
                      id="builder-list-top"
                    >
                      Select a builder for this community
                    </Dialog.Title>

                    <div className="mt-5">
                      <span className="font-aw-serif mb-3 text-lg font-bold text-aw-blue-600 inline-block">
                        Recently Selected
                      </span>
                      <div>
                        <div className="grid grid-cols-fill-80 gap-4">
                          {recentBuildersData.map((builder) => (
                            <GeneratorBuilderCard
                              builder={builder}
                              key={builder.id}
                              setOpen={setOpen}
                              setBuilder={setBuilder}
                            />
                          ))}
                        </div>

                        <div className="my-10 flex flex-wrap justify-center font-aw-sans text-lg">
                          <span className="font-bold">Jump to:</span>{' '}
                          {alphabet.map((letter) => {
                            const disabled = !allBuildersSorted[letter];
                            return (
                              <button
                                key={letter}
                                className={`mx-4 ${
                                  disabled
                                    ? ''
                                    : 'hover:text-aw-blue-600 text-aw-blue-400'
                                }`}
                                onClick={() => {
                                  const element =
                                    document.getElementById(letter);
                                  if (element) {
                                    element.scrollIntoView({
                                      behavior: 'smooth',
                                    });
                                  }
                                }}
                                disabled={disabled}
                              >
                                {letter}
                              </button>
                            );
                          })}
                        </div>

                        <div className="mt-4">
                          {allBuildersSortedArray.map((letter) => {
                            return letter[1].length ? (
                              <Fragment key={letter[0]}>
                                <div className="relative my-20">
                                  <div
                                    className="mb-4 font-aw-serif border-b pb-1 text-2xl font-bold text-gray-600"
                                    id={letter[0]}
                                  >
                                    {letter[0]}
                                  </div>
                                  <div className="grid grid-cols-fill-80 gap-4">
                                    {letter[1].map((builder) => (
                                      <GeneratorBuilderCard
                                        builder={builder}
                                        key={builder.id}
                                        setOpen={setOpen}
                                        setBuilder={setBuilder}
                                      />
                                    ))}
                                  </div>
                                  <button
                                    className="text-aw-blue-600 absolute -bottom-10 hover:text-aw-blue-400"
                                    onClick={() => {
                                      const element =
                                        document.getElementById(
                                          'builder-list-top'
                                        );
                                      if (element) {
                                        element.scrollIntoView({
                                          behavior: 'smooth',
                                        });
                                      }
                                    }}
                                  >
                                    Back to top
                                  </button>
                                </div>
                              </Fragment>
                            ) : null;
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default GeneratorSelectBuilder;
