import React from 'react';
import LoadingSpinner from '../LoadingSpinner';

function LoaderModal({ show }) {
  return (
    show && (
      <div className="fixed inset-0 bg-black opacity-50 z-[100] pt-32">
        <div className="pointer-events-none">
          <LoadingSpinner dark />
        </div>
      </div>
    )
  );
}

export default LoaderModal;
