import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import FakeProgressBar from './FakeProgressBar';

function PublishStatusModal({
  show,
  setShow,
  loadType,
  error,
  url,
  publishComplete,
  communityName,
}) {
  const messages = {
    preview: {
      title: publishComplete ? 'Success!' : 'Generating Preview',
      body: (
        <>
          {publishComplete ? (
            <>
              <button
                type="button"
                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 absolute top-3 right-3"
                onClick={() => {
                  setShow(false);
                }}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-10 w-10" aria-hidden="true" />
              </button>
              <p className="font-aw-sans text-center">
                Click the link below to view your preview:
                <br />
              </p>
              <div className="mt-4 font-aw-sans text-center flex items-center">
                <CheckCircleIcon className="w-12 text-green-400 pr-2" />
                <a
                  href={url}
                  target="_blank"
                  className="text-aw-blue-400 text-2xl underline "
                  rel="noreferrer noopener"
                >
                  {communityName}
                </a>
              </div>
            </>
          ) : (
            <>
              <p className="font-aw-sans text-center">
                This could take several minutes.
                <br />
                Please do not navigate away from this page.
                <br />
              </p>
              <div className="mt-6 w-[500px]">
                <FakeProgressBar loadType={loadType} />
              </div>
            </>
          )}
        </>
      ),
    },
    publish: {
      title: publishComplete ? 'Success!' : 'Generating Microsite',
      body: (
        <>
          {publishComplete ? (
            <>
              <button
                type="button"
                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 absolute top-3 right-3"
                onClick={() => {
                  setShow(false);
                }}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-10 w-10" aria-hidden="true" />
              </button>
              <p className="font-aw-sans text-center">
                Your microsite is now published.
                <br />
                Click the link below to view your microsite:
                <br />
              </p>
              <div className="mt-4 font-aw-sans text-center flex items-center">
                <CheckCircleIcon className="w-12 text-green-400 pr-2" />
                <a
                  href={url}
                  target="_blank"
                  className="text-aw-blue-400 text-2xl underline"
                  rel="noreferrer noopener"
                >
                  {communityName}
                </a>
              </div>
            </>
          ) : (
            <>
              <p className="font-aw-sans text-center">
                This could take several minutes.
                <br />
                Please do not navigate away from this page.
              </p>
              <div className="mt-6">
                <FakeProgressBar loadType={loadType} />
              </div>
            </>
          )}
        </>
      ),
    },
    unpublish: {
      title: 'Unpublishing Microsite',
      body: (
        <>
          <p className="font-aw-sans text-center">
            This could take several minutes.
            <br />
            Please do not navigate away from this page.
          </p>
          <div className="mt-6">
            <FakeProgressBar loadType={loadType} />
          </div>
        </>
      ),
    },
    clone: {
      title: 'Cloning Microsite',
      body: (
        <>
          <p className="font-aw-sans text-center">
            This will take a moment.
            <br />
            Please do not navigate away from this page.
          </p>
          <div className="mt-6">
            <FakeProgressBar loadType={loadType} />
          </div>
        </>
      ),
    },
  };

  const { title, body } = messages[loadType];

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl ">
                <div className="flex items-center flex-col pt-8 pb-12 min-h-[350px] justify-center">
                  {!error ? (
                    <>
                      <p className="font-aw-serif text-3xl mb-4 text-aw-blue-600">
                        {title}
                      </p>
                      {body}
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 absolute top-3 right-3"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-10 w-10" aria-hidden="true" />
                      </button>
                      <p className="font-aw-serif text-3xl mb-2">
                        Something went wrong
                      </p>
                      <p className="font-aw-sans text-center">
                        Please refresh this page and try again.
                      </p>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default PublishStatusModal;
