import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import HeaderMenu from './containers/HeaderMenu';
import netlifyIdentity from 'netlify-identity-widget';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { getLink } from './hooks/useApolloLink';
import ErrorBoundary from './components/ErrorBoundary';

function App() {
  const [currentUser, setCurrentUser] = useState(netlifyIdentity.currentUser());
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useRef(false);

  /**
   * Apollo client instance. Uses useMemo to recreate the client only when currentUser changes, rather than on every render.
   *
   * @type {ApolloClient}
   */
  const client = useMemo(() => {
    return new ApolloClient({
      link: getLink(currentUser),
      cache: new InMemoryCache({
        typePolicies: {
          Community: {
            read(existing) {
              return existing;
            },
            keyArgs: false,
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming);
            },
            fields: {
              cabinetLevelsCollection: {
                read: (existing) => existing,
                keyArgs: ['id'],
                merge: mergeCollection,
              },
              hardwareLevelsCollection: {
                read: (existing) => existing,
                keyArgs: ['id'],
                merge: mergeCollection,
              },
              roomsCollection: {
                read: (existing) => existing,
                keyArgs: ['id'],
                merge: mergeCollection,
              },
            },
          },
        },
      }),
    });
  }, [currentUser]); // Recreate the client only when currentUser changes

  const handleIdentityEvents = useCallback(
    (user) => {
      if (user) {
        setCurrentUser(user);
        netlifyIdentity.close();
      } else {
        setCurrentUser(null);
      }
    },
    [setCurrentUser]
  );

  const removeNetlifyBranding = () => {
    let brandingElement = document
      .getElementById('netlify-identity-widget')
      .contentWindow.document.getElementsByClassName('callOut')[0];
    brandingElement.style.display = 'none';
  };

  useEffect(() => {
    // Remove token from local storage if stale
    const goTrueStorage = JSON.parse(localStorage.getItem('gotrue.user'));
    const expiresAt = new Date(goTrueStorage?.token.expires_at);
    if (expiresAt <= new Date()) {
      localStorage.removeItem('gotrue.user');
      netlifyIdentity.logout();
    }

    // Configure netlifyIdentity
    netlifyIdentity.on('init', handleIdentityEvents);
    netlifyIdentity.on('login', handleIdentityEvents);
    netlifyIdentity.on('logout', handleIdentityEvents);
    netlifyIdentity.on('error', console.error);
    netlifyIdentity.on('open', removeNetlifyBranding);

    return () => {
      netlifyIdentity.off('init', handleIdentityEvents);
      netlifyIdentity.off('login', handleIdentityEvents);
      netlifyIdentity.off('logout', handleIdentityEvents);
      netlifyIdentity.off('error', console.error);
    };
  }, [handleIdentityEvents]);

  useEffect(() => {
    if (!isMounted.current) {
      // skip the first invocation to avoid navigating to the hash twice
      isMounted.current = true;
      return;
    }

    navigate(`/${location.hash}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <div>
      <ErrorBoundary>
        <ApolloProvider client={client}>
          <HeaderMenu currentUser={currentUser} />
          <Outlet context={{ currentUser, setCurrentUser }} />
        </ApolloProvider>
      </ErrorBoundary>
    </div>
  );
}

function mergeCollection(existing = {}, incoming = {}) {
  const hash = {};
  const extract = (item) => (hash[item.sys.__ref] = item);
  existing?.items?.forEach(extract);
  incoming?.items?.forEach(extract);
  return {
    total: existing?.total || incoming?.total || 0,
    items: Object.values(hash),
  };
}

export default App;
