import React from 'react';

function PreviewIcon({ className }) {
  return (
    <svg
      viewBox="0 0 36 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} fill-current`}
    >
      <path
        d="M1.5 8.25A1.5 1.5 0 0 0 3 6.75V4.5C3 3.672 3.674 3 4.5 3h2.25a1.5 1.5 0 1 0 0-3H4.5A4.505 4.505 0 0 0 0 4.5v2.25c0 .83.672 1.5 1.5 1.5ZM31.5 0h-2.25a1.5 1.5 0 1 0 0 3h2.25c.827 0 1.5.672 1.5 1.5v2.25a1.5 1.5 0 1 0 3 0V4.5C36 2.019 33.981 0 31.5 0ZM0 25.5C0 27.981 2.019 30 4.5 30h2.25a1.5 1.5 0 1 0 0-3H4.5c-.826 0-1.5-.672-1.5-1.5v-2.25a1.5 1.5 0 1 0-3 0v2.25ZM34.5 21.75a1.5 1.5 0 0 0-1.5 1.5v2.25c0 .828-.673 1.5-1.5 1.5h-2.25a1.5 1.5 0 1 0 0 3h2.25c2.481 0 4.5-2.019 4.5-4.5v-2.25a1.5 1.5 0 0 0-1.5-1.5ZM18 24.105c5.775 0 10.695-3.63 11.97-8.835.045-.18.045-.36 0-.54C28.695 9.524 23.775 5.894 18 5.894S7.305 9.525 6.03 14.73c-.045.18-.045.36 0 .54 1.275 5.204 6.195 8.835 11.97 8.835Zm0-13.606c2.475 0 4.5 2.025 4.5 4.5s-2.025 4.5-4.5 4.5a4.513 4.513 0 0 1-4.5-4.5c0-2.475 2.025-4.5 4.5-4.5Z"
        className="fill-current"
      />
    </svg>
  );
}

export default PreviewIcon;
