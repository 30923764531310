import React from 'react';

function ButtonPrimary({
  children,
  onClick,
  disabled = false,
  className,
  id,
  type = 'button',
}) {
  return (
    <button
      className={`${className} flex h-[55px] items-center shadow-md rounded ${disabled ? 'bg-aw-gray-200':'bg-aw-blue-400'} py-2 px-3 font-aw-sans font-bold text-white hover:opacity-90 lg:py-4 lg:px-6`}
      onClick={onClick}
      disabled={disabled}
      type={type}
      id={id}
    >
      {children}
    </button>
  );
}

export default ButtonPrimary;
