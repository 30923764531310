/**
 * Error component.
 * Renders an error message when something goes wrong.
 */
import React from 'react';

export default function Error() {
  return (
    <>
      <div className="text-center mt-24">
        <h1 className="mt-4 text-3xl text-gray-900 sm:text-5xl font-aw-serif">
          Something went wrong
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600 font-aw-sans">
          Please refresh this page and try again.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6"></div>
      </div>
    </>
  );
}
