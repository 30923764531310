const AccountIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      d="M34.142 5.858A19.87 19.87 0 0 0 20 0 19.87 19.87 0 0 0 5.858 5.858 19.87 19.87 0 0 0 0 20a19.87 19.87 0 0 0 5.858 14.142A19.87 19.87 0 0 0 20 40a19.87 19.87 0 0 0 14.142-5.858A19.87 19.87 0 0 0 40 20a19.87 19.87 0 0 0-5.858-14.142ZM20 37.656a17.595 17.595 0 0 1-12.25-4.954A12.912 12.912 0 0 1 20 23.828c5.587 0 10.53 3.616 12.251 8.874A17.595 17.595 0 0 1 20 37.656Zm-5.86-22.031c0-3.23 2.63-5.86 5.86-5.86 3.23 0 5.86 2.63 5.86 5.86 0 3.23-2.63 5.86-5.86 5.86a5.866 5.866 0 0 1-5.86-5.86Zm19.87 15.106a15.278 15.278 0 0 0-9.2-8.465 8.197 8.197 0 0 0 3.393-6.641c0-4.523-3.68-8.203-8.203-8.203s-8.203 3.68-8.203 8.203a8.197 8.197 0 0 0 3.393 6.64 15.278 15.278 0 0 0-9.2 8.466A17.566 17.566 0 0 1 2.343 20c0-9.736 7.92-17.656 17.656-17.656 9.736 0 17.656 7.92 17.656 17.656 0 4.033-1.36 7.755-3.645 10.73Z"
      fill="#000"
    />
  </svg>
);

export default AccountIcon;
