import { gql } from '@apollo/client';

export const GET_GALLERY_IMAGES = gql`
  query GetImages($tags: [String!], $skip: Int, $limit: Int) {
    assetCollection(
      where: { contentfulMetadata: { tags: { id_contains_all: $tags } } }
      skip: $skip
      limit: $limit
    ) {
      total
      items {
        url
        fileName
        title
        sys {
          id
        }
      }
    }
  }
`;

export const GET_GALLERY_IMAGES_WITH_UPLOADS = gql`
  query GetImages(
    $builderTag: String!
    $imageType: String!
    $skip: Int
    $limit: Int
  ) {
    assetCollection(
      where: {
        OR: [
          {
            contentfulMetadata: {
              tags: { id_contains_all: [$imageType, $builderTag] }
            }
          }
          {
            contentfulMetadata: {
              tags: { id_contains_all: [$imageType, "gallery"] }
            }
          }
        ]
      }
      skip: $skip
      limit: $limit
    ) {
      total
      items {
        url
        title
        width
        height
        fileName
        sys {
          id
        }
      }
    }
  }
`;
