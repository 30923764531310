const AddNewIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
    {...props}
  >
    <path
      d="M18.506 0H6.312a2.497 2.497 0 0 0-2.494 2.494v1.324H2.494A2.497 2.497 0 0 0 0 6.312v12.194A2.497 2.497 0 0 0 2.494 21h12.194a2.497 2.497 0 0 0 2.494-2.494v-1.324h1.324A2.497 2.497 0 0 0 21 14.688V2.494A2.497 2.497 0 0 0 18.506 0Zm-3.233 18.506a.586.586 0 0 1-.585.585H2.494a.586.586 0 0 1-.585-.585V6.312a.586.586 0 0 1 .585-.585h12.194a.586.586 0 0 1 .585.585v12.194Zm3.818-3.818a.586.586 0 0 1-.585.585h-1.324v-8.96a2.497 2.497 0 0 0-2.494-2.495h-8.96V2.494a.586.586 0 0 1 .584-.585h12.194a.586.586 0 0 1 .585.585v12.194Z"
      fill="#fff"
    />
    <path
      d="M12.409 11.454H9.545V8.591a.955.955 0 1 0-1.909 0v2.863H4.773a.955.955 0 0 0 0 1.91h2.863v2.863a.955.955 0 1 0 1.91 0v-2.864h2.863a.955.955 0 0 0 0-1.909Z"
      fill="#fff"
    />
  </svg>
);

export default AddNewIcon;
