import { useState, useRef } from 'react';
import { useQuery, gql } from '@apollo/client';
import { PRODUCTS_COLLECTION_LIMIT, ROOMS_QUERY_LIMIT } from '../helpers';

export function useCommunityRoomsQuery(id, onUpdate) {
  const [formattedData, setFormattedData] = useState({});
  const perPage = useRef(ROOMS_QUERY_LIMIT);
  const lastLoadSkip = useRef(null);
  const retries = useRef(0);
  const maxRetries = useRef(3);

  const formatData = (data) => {
    const { community } = data;
    const { roomsCollection } = community;
    const rooms = roomsCollection?.items?.map((room) => {
      const image = room.image || {};
      const headerImage = room.headerImage || {};
      const products = room.productsCollection.items.map(
        (product) => product.sys?.id
      );

      return {
        id: room.sys?.id ?? null,
        name: room.name,
        image: {
          title: image.title || null,
          id: image.sys?.id || null,
          fileName: image.fileName || null,
          url: image.url || null,
        },
        headerImage: {
          fileName: headerImage.fileName || null,
          url: headerImage.url || null,
          id: headerImage.sys?.id || null,
        },
        products,
      };
    });
    const uniqueCabinetSelections = [];
    const uniqueHardwareSelections = [];
    roomsCollection?.items?.forEach((room) => {
      room?.productsCollection?.items?.forEach((product) => {
        if (
          product?.productLine?.type === 'Hardware' &&
          !uniqueHardwareSelections.includes(product?.sys?.id)
        ) {
          uniqueHardwareSelections.push(product?.sys?.id);
        } else if (
          product?.productLine?.type === 'Cabinet' &&
          !uniqueCabinetSelections.includes(product?.sys?.id)
        ) {
          uniqueCabinetSelections.push(product?.sys?.id);
        }
      });
    });
    return {
      rooms: rooms || [],
      uniqueCabinetSelections: uniqueCabinetSelections || [],
      uniqueHardwareSelections: uniqueHardwareSelections || [],
    };
  };

  const GET_SINGLE_COMMUNITY_ROOMS = gql`
    query GetSingleCommunityRooms($id: String!, $skip: Int) {
      community(id: $id) {
        roomsCollection(limit: ${perPage.current}, skip: $skip) {
          total
          items {
            headerImage {
              sys {
                id
              }
              fileName
              url
            }
            name
            productsCollection(limit: ${PRODUCTS_COLLECTION_LIMIT}) {
              items {
                sys {
                  id
                }
                productLine {
                  type
                }
              }
            }
            sys {
              id
            }
            image {
              title
              sys {
                id
              }
              fileName
              url
            }
          }
        }
      }
    }
  `;

  const { loading, error, data, fetchMore } = useQuery(
    GET_SINGLE_COMMUNITY_ROOMS,
    {
      variables: { id, skip: 0 },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      onCompleted
    }
  );

  function onCompleted(rawData) {
    const formattedData = formatData(rawData);
    setFormattedData(formattedData);
    if(onUpdate) {
      onUpdate(formattedData, rawData);
    }
    requestAdditionalPages(rawData);
  }

  function requestAdditionalPages(rawData) {
    const loaded = rawData?.community?.roomsCollection?.items?.length;
    const total = rawData?.community?.roomsCollection?.total;

    const isRetry = lastLoadSkip.current === loaded;
    if(!isRetry) {
      retries.current = 0;
    }
    
    const hitRetryLimit = retries.current >= maxRetries.current
    if(hitRetryLimit || (loaded >= total)) {
      return;
    }

    lastLoadSkip.current = loaded;
    retries.current += 1;
    fetchMore({
      variables: {
        skip: loaded,
      },
    });
  }

  return { data, formattedData, loading, error };
}
