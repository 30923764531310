import React, { useCallback, useState } from 'react';
import FieldLabel from '../forms/FieldLabel';
import DeleteIcon from '../icons/DeleteIcon';
import { useOutletContext } from 'react-router-dom';
import { RecommendedItemSelect } from '../forms';
import ImageUploadOrSelect from '../ImageUploadOrSelect';
import { useContentful } from '../../hooks/useContentful';
import { DELETE_RECOMMENDATION } from '../../constants/contentfulActions';
import Speedbump from '../modals/Speedbump';
import LoaderModal from '../modals/LoaderModal';
import ErrorMessage from '../ErrorMessage';

function GeneratorRecommendationCard({
  currentRecommendation,
  index,
  products,
  communityId,
  errors,
  setErrors,
  validateFields,
}) {
  const { communityData, setCommunityData, builderImageTag } =
    useOutletContext();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [dispatchDeleteRecommendation, deleteRecommendationState] =
    useContentful();

  const handleDeleteRecommendation = async () => {
    setConfirmDeleteOpen(false);
    await dispatchDeleteRecommendation({
      type: DELETE_RECOMMENDATION,
      payload: {
        deleteRecommendationCommunityId: communityId,
        deleteRecommendationId: currentRecommendation.id,
      },
    });
    const newRecommendationsData = [...communityData.recommendations];
    newRecommendationsData.splice(index, 1);
    setCommunityData({
      ...communityData,
      recommendations: newRecommendationsData,
    });
  };

  const handleSetRecommendation = useCallback(
    (value) => {
      setCommunityData((prevState) => {
        const newRecommendations = [...prevState.recommendations];
        newRecommendations[index] = {
          ...newRecommendations[index],
          product: { id: value },
        };
        return {
          ...prevState,
          recommendations: newRecommendations,
        };
      });
    },
    [setCommunityData, index]
  );

  const handleSelectImage = useCallback(
    (image) => {
      setCommunityData((prevState) => {
        const newRecommendations = [...prevState.recommendations];
        newRecommendations[index] = {
          ...newRecommendations[index],
          image: image,
        };
        return {
          ...prevState,
          recommendations: newRecommendations,
        };
      });
    },
    [setCommunityData, index]
  );

  const formattedProducts = products
    .filter((product) => product.productLine.type !== 'Hardware')
    .map((product) => ({
      productId: product.sys.id,
      productName: product.productLine.name,
      productCode: product.productCode,
      finish: product.finish,
      wood: product.wood,
      image: product.image.url,
      type: product.productLine.type,
    }));

  const selectedProductData = formattedProducts.find(
    (item) => item.productId === currentRecommendation.product.id
  );

  return (
    <>
      <Speedbump
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={handleDeleteRecommendation}
        confirmTitle="Delete Recommendation"
        confirmBody="Are you sure you want to delete this recommendation? This action cannot be undone."
        confirmBtnText="Delete"
      />
      <LoaderModal show={deleteRecommendationState.loading} />
      <div className="mt-10 border-b pb-8">
        <div className=" flex gap-14">
          <div className="w-full flex flex-col">
            <div className="w-full">
              <RecommendedItemSelect
                allProducts={formattedProducts}
                selectedProduct={currentRecommendation}
                setSelectedProduct={handleSetRecommendation}
                currentRecommendation={currentRecommendation}
                selectedProductData={selectedProductData}
                allCurrentSelectedProducts={communityData.recommendations}
              />
              <ErrorMessage show={errors?.product !== null}>
                {errors?.product}
              </ErrorMessage>
            </div>
            <div className="w-full mt-4">
              {currentRecommendation !== '' && (
                <>
                  <FieldLabel>Product image:</FieldLabel>
                  <div className="w-full flex mt-4">
                    <img
                      src={selectedProductData?.image}
                      className="w-44"
                      alt={selectedProductData?.finish}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="w-1/2">
            <FieldLabel>
              Upload or select lifestyle image:
              <br />
              <span className="inline text-xs text-aw-gray-300">
                900x440 recommended
              </span>
            </FieldLabel>
            <ImageUploadOrSelect
              setImage={handleSelectImage}
              currentImage={currentRecommendation.image}
              targetEntryId={currentRecommendation.id}
              tagsToAddToUploads={[builderImageTag, 'hero']}
              imageType={`Recommendation ${index + 1}`}
              imageTagToQuery="productRender"
              aspect="portrait"
            />
            <ErrorMessage show={errors?.image !== null}>
              {errors?.image}
            </ErrorMessage>
          </div>
        </div>

        <div
          className="mt-4 flex w-min cursor-pointer rounded-lg border py-2 px-4 text-aw-gray-700 hover:fill-red-400 hover:text-red-400 hover:border-red-400"
          onClick={() => setConfirmDeleteOpen(true)}
        >
          <DeleteIcon className="mr-3 fill-current" />
          <span className="font-aw-sans">Delete</span>
        </div>
      </div>
    </>
  );
}

export default GeneratorRecommendationCard;
