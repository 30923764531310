import React from 'react';

function LoadingSpinner({dark=false}) {
  return (
    <div className="mt-12 flex h-screen max-h-96 w-full items-center justify-center">
      <div className="flex h-16 w-16 animate-spin items-center justify-center rounded-full bg-gradient-to-tr from-aw-gray-200 to-aw-blue-600">
        <div className={`h-12 w-12 rounded-full ${dark ? 'bg-black':'bg-aw-gray-100'}`}></div>
      </div>
    </div>
  );
}

export default LoadingSpinner;
