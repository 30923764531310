export const CREATE_ENTRY = 'CREATE_ENTRY';
export const CREATE_COMMUNITY = 'CREATE_COMMUNITY';
export const CREATE_HARDWARE_LEVEL = 'CREATE_HARDWARE_LEVEL';
export const CREATE_CABINET_LEVEL = 'CREATE_CABINET_LEVEL';
export const CREATE_UNCATEGORIZED_CABINET_LEVEL =
  'CREATE_UNCATEGORIZED_CABINET_LEVEL';
export const CREATE_UNCATEGORIZED_HARDWARE_LEVEL =
  'CREATE_UNCATEGORIZED_HARDWARE_LEVEL';
export const CLONE_COMMUNITY = 'CLONE_COMMUNITY';
export const UPDATE_ENTRY = 'UPDATE_ENTRY';
export const UPDATE_COMMUNITY = 'UPDATE_COMMUNITY';
export const UPDATE_PUBLISHED_STATUS = 'UPDATE_PUBLISHED_STATUS';
export const DELETE_ENTRY = 'DELETE_ENTRY';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const CREATE_ROOM = 'CREATE_ROOM';
export const CREATE_RECOMMENDATION = 'CREATE_RECOMMENDATION';
export const CREATE_ADD_ON = 'CREATE_ADD_ON';
export const CREATE_USER = 'CREATE_USER';
export const DELETE_COMMUNITY = 'DELETE_COMMUNITY';
export const DELETE_ROOM = 'DELETE_ROOM';
export const DELETE_RECOMMENDATION = 'DELETE_RECOMMENDATION';
export const DELETE_ADD_ON = 'DELETE_ADD_ON';
export const REMOVE_PREVIEW_URL = 'REMOVE_PREVIEW_URL';
