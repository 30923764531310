import React, { useEffect, useState } from 'react';
// import { useOutletContext } from 'react-router-dom';
import { SizeConstrained } from '../../components/layout/Layout';
import { useParams } from 'react-router-dom';
import { H1 } from '../../components/typography';
import { useOutletContext } from 'react-router-dom';
import slugify from 'slugify';
import EditIcon from '../../components/icons/EditIcon';
import { FieldLabel, TextInput } from '../../components/forms';
import ErrorMessage from '../../components/ErrorMessage';
import {
  checkCommunityNameUnique,
  extractCommunityNamesFromBuilders,
} from '../../graphql/helpers';
import { useContentful } from '../../hooks/useContentful';
import { UPDATE_COMMUNITY } from '../../constants/contentfulActions';
import ImageUploadOrSelect from '../../components/ImageUploadOrSelect';
import GeneratorPublishMenu from '../../components/generator/GeneratorPublishMenu';
import LoaderModal from '../../components/modals/LoaderModal';

function GeneratorPublish() {
  const { id } = useParams();
  const {
    communityData,
    setCommunityData,
    initialCommunityName,
    refetchCommunityData,
    builderImageTag,
  } = useOutletContext();
  const [newCommunityName, setNewCommunityName] = useState(communityData.name);
  const [editTitleOpen, setEditTitleOpen] = useState(false);
  const [dispatchUpdateCommunity, updateCommunityState, resetContentfulState] =
    useContentful();

  useEffect(() => {
    if (updateCommunityState.success) {
      setEditTitleOpen(false);
      refetchCommunityData();
      resetContentfulState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCommunityState.success]);

  const handleUpdateCommunity = async (updateCommunityData) => {
    await dispatchUpdateCommunity({
      type: UPDATE_COMMUNITY,
      payload: {
        communityData: updateCommunityData,
      },
    });
    setCommunityData(updateCommunityData);
  };

  const currentExistingCommunityNames = extractCommunityNamesFromBuilders(
    communityData.builderCurrentCommunities
  );


  const isCommunityNameValid = checkCommunityNameUnique(
    newCommunityName,
    currentExistingCommunityNames,
    initialCommunityName
  );

  const handleSetBannerImage = (image) => {
    const updateCommunityData = { ...communityData, banner: image };
    handleUpdateCommunity(updateCommunityData);
  };

  const handleSetCommunityName = () => {
    const updatedCommunityData = {
      ...communityData,
      name: newCommunityName,
    };
    handleUpdateCommunity(updatedCommunityData);
  };

  return (
    <>
      <LoaderModal show={updateCommunityState.loading} />
      <SizeConstrained>
        <div>
          <div className="border-b-1 mb-12  justify-between border-b pb-16">
            <H1>Review &amp; Publish</H1>
            <p className="font-aw-serif mt-6">
              {communityData.publishedUrl
                ? 'Your microsite changes are ready to be published.'
                : 'Your microsite is ready to be reviewed and published online. You will receive a shareable link to your microsite once it is published.'}
            </p>
            <div className="flex mt-10 justify-between flex-col">
              <div className="flex">
                <span className="font-aw-sans">Community name: </span>
                {!editTitleOpen ? (
                  <>
                    <span className="font-bold ml-6 font-aw-sans">
                      {communityData.name}
                    </span>
                    <button
                      onClick={() => setEditTitleOpen(true)}
                      className="ml-6 font-aw-sans text-aw-blue-400 flex"
                    >
                      <EditIcon className="fill-aw-blue-400 mr-1" />
                      edit{' '}
                    </button>{' '}
                  </>
                ) : (
                  <div className="w-1/2 relative -mt-4 ml-4 mb-4">
                    <div className="flex">
                      <div className="w-full">
                        <TextInput
                          value={newCommunityName}
                          onChange={(e) => setNewCommunityName(e.target.value)}
                        />
                      </div>
                      <button
                        className="mt-4 ml-4 text-red-400"
                        onClick={() => {
                          setNewCommunityName('');
                          setEditTitleOpen(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="mt-4 ml-4 text-aw-blue-400 disabled:text-gray-300"
                        onClick={handleSetCommunityName}
                        disabled={!isCommunityNameValid}
                      >
                        Save
                      </button>
                    </div>
                    <ErrorMessage show={!isCommunityNameValid}>
                      Community name already exists for this builder. Please
                      choose a different one.
                    </ErrorMessage>
                  </div>
                )}
              </div>
              {communityData.publishedUrl && (
                <p className="font-aw-sans mt-4">
                  Microsite URL:{' '}
                  <a
                    className="font-aw-sans ml-6 text-aw-blue-400 "
                    target="_blank"
                    href={communityData.publishedUrl}
                    rel="noreferrer"
                  >
                    {communityData.publishedUrl}
                  </a>
                </p>
              )}
            </div>
          </div>
          <div className="mb-16">
            <FieldLabel>
              Banner image:{' '}
              <span className="ml-4 inline text-xs text-aw-gray-300">
                3080x840 recommended
              </span>
            </FieldLabel>
            <ImageUploadOrSelect
              imageType="Banner Image"
              setImage={handleSetBannerImage}
              currentImage={communityData?.banner ?? null}
              targetEntryId={communityData.id}
              tagsToAddToUploads={[builderImageTag, 'hero']}
              imageTagToQuery="hero"
            />
          </div>
        </div>

        <GeneratorPublishMenu
          prevRoute={`/generator/${id}/addons`}
          publishDisabled={!isCommunityNameValid}
        />
      </SizeConstrained>
    </>
  );
}

export default GeneratorPublish;
