import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function RecommendedItemSelect({
  allProducts,
  selectedProduct,
  setSelectedProduct,
  selectedProductData,
  allCurrentSelectedProducts,
}) {
  const disabled =
    allCurrentSelectedProducts.length === allProducts.length &&
    selectedProduct !== '';

  return (
    <Listbox
      value={selectedProduct.id}
      onChange={(value) => setSelectedProduct(value)}
    >
      {({ open }) => (
        <>
          <Listbox.Label className="text-md block font-aw-sans text-gray-700">
            Select product:
          </Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="block w-full bg-white rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm h-[55px]">
              <span className="flex items-center">
                {selectedProductData?.image ? (
                  <img
                    src={`${selectedProductData.image}?w=200&q=90&&f=bottom_left&fit=scale&fm=jpg&q=85`}
                    alt=""
                    className="object-none h-10 w-20 ml-2 object-left-bottom"
                  />
                ) : (
                  <></>
                )}
                <span className="ml-3 block truncate font-aw-sans">
                  {selectedProductData?.productName
                    ? `${selectedProductData?.productName} - `
                    : ''}
                  {selectedProductData?.finish
                    ? selectedProductData?.finish
                    : 'Select Product'}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-80 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {disabled ? (
                  <Listbox.Option
                    disabled
                    className="text-gray-500 italic font-aw-sans p-4"
                  >
                    All available products have been selected
                  </Listbox.Option>
                ) : (
                  allProducts.map((item) => {
                    if (allCurrentSelectedProducts.includes(item.productId)) {
                      return null;
                    }
                    return (
                      <Listbox.Option
                        key={item.productId}
                        className={({ active }) =>
                          classNames(
                            active
                              ? 'bg-indigo-600 text-white'
                              : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={item.productId}
                      >
                        {({ selected, active }) => (
                          <>
                            <div className="flex items-center">
                              <img
                                src={`${item.image}?w=200&q=90&&f=bottom_left&fit=scale&fm=jpg&q=85`}
                                alt=""
                                className="object-none min-w-[130px] h-[90px] min-h-[90px] object-left-bottom"
                              />
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'ml-3 block truncate font-aw-sans'
                                )}
                              >
                                {item.productName} - {item.wood} {item.finish}
                              </span>
                            </div>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    );
                  })
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
