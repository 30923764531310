import React from 'react';

function H1({ children, className = '' }) {
  let classes = className;
  const defaultClasses =
    'font-aw-serif text-3xl font-extrabold tracking-tight text-aw-blue-600 sm:text-4xl';

  if (className) {
    const defaultClassesArray = defaultClasses.split(' ');
    const classesArray = className.split(' ');
    defaultClassesArray.forEach((defaultClass) => {
      if (classesArray.includes(defaultClass)) {
        classes = classes.replace(defaultClass, '');
      } else {
        classes = `${classes} ${defaultClass}`;
      }
    });
  } else {
    classes = defaultClasses;
  }

  return <h1 className={classes}>{children}</h1>;
}

export default H1;
