import React from 'react';

function H2({ children }) {
  return (
    <>
      <h2 className="font-aw-serif text-3xl font-extrabold tracking-tight text-aw-blue-600">
        {children}
      </h2>
    </>
  );
}

export default H2;
