import { gql } from '@apollo/client';

export const GET_BUILDERS = gql`
  query GetBuilders($email: String!) {
    userCollection(where: { email: $email }) {
      items {
        email
        recentBuildersCollection {
          items {
            sys {
              id
            }
          }
        }
      }
    }
    builderCollection {
      items {
        name
        builderTag
        url
        sys {
          id
        }
        logo {
          title
          url
        }
      }
    }
  }
`;
