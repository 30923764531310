import React from 'react';

const GeneratorBuilderCard = ({
  builder,
  isSelected = false,
  setOpen,
  isNewCommunity = false,
  handleRemoveBuilder,
  setBuilder,
}) => {
  const { name, url, logo } = builder || {};

  const handleLogo = (logo) => {
    if (typeof logo === 'string' || !logo) {
      return logo;
    } else {
      return logo.url;
    }
  };

  return (
    <>
      <div
        className={`border-1 mt-4 rounded-lg border max-w-sm bg-white shadow-lg ${
          isSelected ? 'lg:max-w-none  lg:flex gap-4' : ''
        }`}
      >
        <img
          className={`px-2 py-4 aspect-video  object-contain ${
            isSelected ? ' w-full lg:w-1/2' : 'w-full'
          }`}
          src={handleLogo(logo)}
          alt={name}
        />{' '}
        <div className="border-t p-4">
          <div className="">
            <div className="mb-2 text-xl font-bold  font-aw-sans">{name}</div>
            <a
              href={url}
              className="text-base text-gray-700 font-aw-sans flex break-all hover:text-gray-500"
            >
              {url}
            </a>
          </div>
          <div>
            {!isSelected ? (
              <div className="py-1">
                <button
                  onClick={() => {
                    setBuilder(builder);
                    setOpen(false);
                  }}
                  className="inline-block py-2 text-aw-blue-600 hover:text-aw-blue-400"
                >
                  Add to community
                </button>
              </div>
            ) : isNewCommunity ? (
              <button
                onClick={() => handleRemoveBuilder()}
                className="inline-block py-2 text-red-400"
              >
                Remove
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneratorBuilderCard;
