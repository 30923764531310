import { gql } from '@apollo/client';
export const GET_CURRENT_USER_ID = gql`
  query GetSingleCommunity($email: String!) {
    userCollection(where: { email: $email }) {
      items {
        sys {
          id
        }
      }
    }
  }
`;
