import { gql } from '@apollo/client';

export const GET_ALL_PRODUCTS = gql`
  query GetProducts {
    productFamilyCollection(limit: 4) {
      items {
        name
        linkedFrom {
          productLineCollection(limit: 39) {
            items {
              name
              sys {
                id
              }
              linkedFrom {
                productCollection(limit: 32) {
                  items {
                    productCode
                    finish
                    wood
                    sys {
                      id
                    }
                    image {
                      url
                    }
                  }
                  total
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_SELECTED_PRODUCTS = gql`
  query GetProductsInArray($ids: [String!]!) {
    productCollection(limit: 1000, where: { sys: { id_in: $ids } }) {
      items {
        productLine {
          name
          type
        }
        productCode
        finish
        wood
        sys {
          id
        }
        image {
          url
        }
      }
    }
  }
`;
