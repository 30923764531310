import React, { useCallback } from 'react';
import { DragDropContext } from '@hello-pangea/dnd';
import { useOutletContext } from 'react-router-dom';

function LevelSelectorContext({
  children,
  selectedIds,
  setSelectedIds,
  findLevelKey,
  levelDataKey,
}) {
  const { communityData, setCommunityData } = useOutletContext();
  const onDragStart = (start) => {
    const selected = selectedIds.includes(start.draggableId);

    // if dragging an item that is not selected - unselect all items
    if (!selected) {
      setSelectedIds([]);
    }
  };

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        // dragged outside of a droppable area, do nothing
        return;
      }
      // multidrag
      if (selectedIds.length) {
        // selectedIds will only contain one key type, can select any index
        const keyOfSelectedIds = findLevelKey(
          communityData[levelDataKey],
          selectedIds[0]
        );

        if (keyOfSelectedIds === result.destination.droppableId) {
          // multidrag within level
          // do nothing - order within level not important
          return;
        }

        // multidrag between columns

        let sourceColumnIds = Array.from(
          communityData[levelDataKey][keyOfSelectedIds].ids
        );
        let destinationColumnIds = Array.from(
          communityData[levelDataKey][result.destination.droppableId].ids
        );
        let filteredSourceColumnIds = sourceColumnIds.filter((id) => {
          return !selectedIds.includes(id);
        });
        let beforeInsert = destinationColumnIds.slice(
          0,
          result.destination.index + 1
        );
        let afterInsert = destinationColumnIds.slice(
          result.destination.index + 1
        );
        let concatenated = beforeInsert.concat(selectedIds).concat(afterInsert);

        let newCommunityData = { ...communityData };
        newCommunityData[levelDataKey][keyOfSelectedIds] = {
          title: newCommunityData[levelDataKey][keyOfSelectedIds].title,
          ids: filteredSourceColumnIds,
        };
        newCommunityData[levelDataKey][result.destination.droppableId] = {
          title:
            newCommunityData[levelDataKey][result.destination.droppableId]
              .title,
          ids: concatenated,
        };
        setCommunityData(newCommunityData);

        setSelectedIds([]);

        return;
      }

      if (result.source.droppableId === result.destination.droppableId) {
        // reordering within column
        let idsCopy = Array.from(
          communityData[levelDataKey][result.source.droppableId].ids
        );
        idsCopy.splice(
          result.destination.index,
          0,
          idsCopy.splice(result.source.index, 1)[0]
        );

        let newCommunityData = { ...communityData };
        newCommunityData[levelDataKey][result.source.droppableId].ids = idsCopy;
        setCommunityData(newCommunityData);

        return;
      }

      // moving between columns
      let destinationIdsCopy = Array.from(
        communityData[levelDataKey][result.destination.droppableId].ids
      );
      let sourceIdsCopy = Array.from(
        communityData[levelDataKey][result.source.droppableId].ids
      );
      sourceIdsCopy.splice(result.source.index, 1);
      destinationIdsCopy.splice(
        result.destination.index,
        0,
        result.draggableId
      );

      let newCommunityData = { ...communityData };

      newCommunityData[levelDataKey][result.source.droppableId].ids =
        sourceIdsCopy;
      newCommunityData[levelDataKey][result.destination.droppableId].ids =
        destinationIdsCopy;
      setCommunityData(newCommunityData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [communityData, setCommunityData, findLevelKey, selectedIds, setSelectedIds]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <div className="py-4 bg-aw-gray-100 flex flex-nowrap">{children}</div>
    </DragDropContext>
  );
}

export default LevelSelectorContext;
