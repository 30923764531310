import React, { useState, useEffect } from 'react';

// The messages that will be displayed in the progress bar, but are outside of the component to avoid re-rendering
const cloneMessages = [
  'Cloning your community. This should only take a minute.',
  'Copying rooms.',
  'Configuring cabinet levels.',
  'Setting up hardware.',
  'Making sure recommendations and add-ons are in place.',
  'Hinge time! Connecting doors for easy access.',
  'Fine-tuning: Adjusting doors and drawers for a flawless finish.',
  'Putting the final touches on your cabinets with molding and trim.',
  'Maximizing storage space with smart cabinet organizers.',
  'Almost there! Just a few more seconds.',
];
const messages = [
  'Finding the perfect spot for those stylish cabinets.',
  'Leveling up: Ensuring your cabinets are perfectly balanced.',
  "It's anchor time! Securing cabinets safely to the wall.",
  'Working our way down: Installing upper cabinets before the base.',
  'Adding some glide to your drawers with smooth slides.',
  'Personalizing your cabinets with the perfect hardware.',
  'Hinge time! Connecting doors for easy access.',
  'Fine-tuning: Adjusting doors and drawers for a flawless finish.',
  'Putting the final touches on your cabinets with molding and trim.',
  'Maximizing storage space with smart cabinet organizers.',
];
const defaultPublishInterval = 15000;
const defaultCloneInterval = 4500;
/**
 * @param {string} props.loadType - The type of load that determines whether to show messages or not
 */
const FakeProgressBar = ({ loadType }) => {
  const [messageIndex, setMessageIndex] = useState(0);
  const showMessages = ['publish', 'preview'].includes(loadType);
  const showCloneMessages = ['clone'].includes(loadType);
  const displayMessages = showCloneMessages ? cloneMessages : messages;

  useEffect(() => {
    let messageInterval;
    const intervalTime = showCloneMessages
      ? defaultCloneInterval
      : defaultPublishInterval;
    if (showMessages || showCloneMessages) {
      messageInterval = setInterval(() => {
        setMessageIndex(
          (prevMessageIndex) => (prevMessageIndex + 1) % displayMessages.length
        );
      }, intervalTime); // update message every 15 seconds
    }

    const finishIntervalTime = showCloneMessages ? 60000 : 360000;
    const finishingMessageTimeout = setTimeout(() => {
      setMessageIndex(displayMessages.length); // set message index to one past the last index
    }, finishIntervalTime);

    return () => {
      if (showMessages || showCloneMessages) {
        clearInterval(messageInterval);
      }
      clearTimeout(finishingMessageTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-[300px] md:w-[500px] max-w-xl mx-auto ">
      <div className="h-8 bg-aw-gray-100 border rounded relative overflow-hidden">
        <div className="h-full bg-gradient-loop w-full max-w-[500px] rounded-tl border-t-2 border-b-2 border-l-2 border-white rounded-bl absolute fill-animation" />
      </div>
      <p className="font-aw-sans text-center h-10 mt-2 w-full">
        {showMessages || showCloneMessages
          ? messageIndex === displayMessages.length
            ? 'Putting the finishing touches on your microsite. Almost there!'
            : displayMessages[messageIndex]
          : ''}
      </p>
    </div>
  );
};

export default FakeProgressBar;
