import { useQuery } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';
import { UPLOAD_IMAGE } from '../constants/contentfulActions';
import { extractImagesData } from '../graphql/helpers';
import {
  GET_GALLERY_IMAGES,
  // GET_GALLERY_IMAGES_WITH_UPLOADS,
} from '../graphql/queries/images';
import { useContentful } from '../hooks/useContentful';
import ButtonPrimary from './buttons/ButtonPrimary';
import ButtonTertiary from './buttons/ButtonTertiary';
import EditIcon from './icons/EditIcon';
import UploadIcon from './icons/UploadIcon';
import LoaderModal from './modals/LoaderModal';
import Speedbump from './modals/Speedbump';
import { P } from './typography';
import Cropper from 'react-easy-crop';
import getCroppedImg from './CropImage';

function ImageUploadOrSelect({
  targetEntryId,
  imageType,
  setImage,
  currentImage,
  tagsToAddToUploads,
  imageTagToQuery,
  aspect = 'landscape',
}) {
  const [open, setOpen] = useState(false);
  const [gallery, setGallery] = useState([]);
  const [unsavedCloseOpen, setUnsavedCloseOpen] = useState(false);
  const [totalImages, setTotalImages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedImageData, setSelectedImageData] = useState({});
  const [selectedMethod, setSelectedMethod] = useState('Browse');
  const [showSizeError, setShowSizeError] = useState(false);
  const [dispatchUploadImage, uploadImageState, resetContentfulState] =
    useContentful();
  const tabs = [{ name: 'Browse' }, { name: 'Upload' }];
  const { communityData } = useOutletContext();
  const limit = 12;
  const { loading: galleryDataLoading, refetch } = useQuery(
    GET_GALLERY_IMAGES,
    {
      variables: {
        tags: [imageTagToQuery, 'gallery'],
        skip: (currentPage - 1) * limit,
        limit: limit,
      },
      onCompleted: (data) => {
        const formattedData = extractImagesData(data);
        const { total, images } = formattedData;
        setGallery(images);
        setTotalImages(total);
      },
    }
  );
  const [cimage, setCimage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [formattedImage, setFormattedImage] = useState(null);
  const [isCropSaved, setIsCropSaved] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const [isCropVisible, setIsCropVisible] = useState(false);

  // This query is for the following possible future use case:
  // 1. uploads from the current builder with a given imageType
  // 2. images from gallery that have this imageType

  // const { loading: galleryDataLoading, refetch } = useQuery(
  //   GET_GALLERY_IMAGES_WITH_UPLOADS,
  //   {
  //     variables: {
  //       builderTag: communityData.builder.builderTag,
  //       imageType: imageType,
  //       skip: (currentPage - 1) * limit,
  //       limit: limit,
  //     },
  //     onCompleted: (data) => {
  //       const formattedData = extractImagesData(data);
  //       const { total, images } = formattedData;
  //       setGallery(images);
  //       setTotalImages(total);
  //     },
  //   }
  // );

  useEffect(() => {
    if (uploadImageState.success) {
      setSelectedImageData({});
      setImage({
        url: uploadImageState.data.fields.file['en-US'].url,
        fileName: uploadImageState.data.fields.file['en-US'].fileName,
        isUploadedImage: true,
        id: uploadImageState.data.sys.id,
      });
    }
    refetch();
    resetContentfulState();
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadImageState.success]);

  const handleUpload = async (event = null) => {
    // if this request isn't coming from the speedbump
    if (event) event.preventDefault();
    setUnsavedCloseOpen(false);
    setOpen(false);

    // use the uploaded file to send to Contentful
    let { file } = selectedImageData.file;

    // if the image was cropped, change file to the cropped image
    if (isCropSaved) {
      // convert the cropped image blob to a stream for Contentful
      const blob = await fetch(croppedImage).then((r) => r.blob());
      file = blob;
    }

    // set file params conditionally based on upload or crop
    let fileParams = {
      contentType: file?.type ?? 'image/png',
      fileName: file?.name ?? Date.now() + '-image',
      file: file ?? selectedImageData.src,
    };

    // prep the payload
    const data = {
      title: fileParams.fileName,
      description: '',
      imageTags: tagsToAddToUploads,
      file: fileParams,
      communityId: communityData.id,
      userId: communityData.userId,
      entryId: targetEntryId,
    };

    // prep Contentful dispatch
    const params = {
      type: UPLOAD_IMAGE,
      payload: data,
      config: {
        entryId: targetEntryId,
      },
    };

    dispatchUploadImage(params);
  };

  const handleGallerySelect = (image) => {
    setImage(image);
    setSelectedImageData({});
    setOpen(false);
  };

  function showCrop(event) {
    event.preventDefault();
    setIsCropVisible(!isCropVisible);
    cropRatio(aspect);
  }

  function cropRatio(str) {
    if (aspect === 'portrait') {
      setAspectRatio(1.36 / 1);
    } else {
      setAspectRatio(3.67 / 1);
    }
  }

  // get the cropped image from the cropped canvas
  const getCroppedImage = useCallback(
    async (event = null) => {
      event.preventDefault();
      try {
        // send uploaded image to get cropped, returns a blob
        const imageCropped = await getCroppedImg(
          cimage,
          croppedAreaPixels,
          rotation
        );
        setIsCropSaved(true);

        setIsCropVisible(!isCropVisible);

        const cropURL = URL.createObjectURL(imageCropped);

        setCroppedImage(cropURL);

      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, rotation, cimage, isCropVisible]
  );

  function handleFileChange(event) {
    setShowSizeError(false);
    const file = event.target.files[0];

    // Check if file size is less than or equal to 10MB
    if (!file) return;
    else if (file.size <= 10000000) {

      const reader = new FileReader();

      // reset crop
      setIsCropSaved(false);
      setIsCropVisible(false);
      setCroppedAreaPixels(null);

      // Revoke the previous URL before creating a new one
      if (cimage) {
        URL.revokeObjectURL(cimage);
      }

      // Create a new object URL for the cropper and update state
      const cropperURL = URL.createObjectURL(file);
      setCimage(cropperURL);      

      // set data for uploaded file
      reader.onload = function (event) {
        setSelectedImageData({
          src: event.target.result,
          fileName: file.name,
          isUploadedImage: true,
          title: file.name,
          description: 'A description for my uploaded image',
          imageTags: tagsToAddToUploads,
          file: {
            contentType: file.type,
            fileName: file.name,
            file: file,
          },

          userId: communityData.userId,
          entryId: targetEntryId,
        });
      };
      reader.readAsDataURL(file);
    } else setShowSizeError(true);
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const handleCloseConfirmDialog = (e) => {
    setUnsavedCloseOpen(false);
    setOpen(false);
    setIsCropVisible(false);
    setIsCropSaved(false);
    setCroppedAreaPixels(null);
    if (cimage) {
      URL.revokeObjectURL(cimage);
    }
  };

  const totalPages = Math.ceil(totalImages / limit);

  return (
    <>
      <LoaderModal show={uploadImageState.loading} />
      <Speedbump
        open={unsavedCloseOpen}
        confirmBody="You've selected an image, but haven't saved it yet. Would you like to save your image?"
        confirmTitle="Save image?"
        confirmBtnText="Save Image"
        onConfirm={handleUpload}
        cancelBtnText="Close"
        warning={false}
        onClose={handleCloseConfirmDialog}
        setOpen={setUnsavedCloseOpen}
      />
      {/* create a cover for the whole screen when loading */}

      {currentImage?.url !== null ? (
        <>
          <div className="flex flex-col mt-4 items-end border-2 border-dashed border-gray-300 p-2">
            <img
              src={currentImage?.url}
              alt={currentImage?.fileName}
              className={`${
                aspect === 'portrait' ? '34/25' : 'aspect-[11/3]'
              } w-full object-cover`}
            />
          </div>
          <div
            onClick={() => setOpen(true)}
            className="w-full cursor-pointer flex mt-2 justify-end"
          >
            <span className="font-aw-sans text-aw-blue-400 flex">
              <EditIcon className="fill-aw-blue-400 mr-1" /> Edit
            </span>
          </div>
        </>
      ) : (
        <div className="mt-4 border-2 border-dashed border-gray-300 p-2">
          <div
            className={`${
              aspect === 'portrait' ? 'aspect-[34/25]' : 'aspect-[11/3]'
            } w-full object-cover`}
          >
            <button
              type="button"
              onClick={() => setOpen(true)}
              className="relative  flex h-full w-full items-center justify-center rounded-lg bg-aw-gray-200 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <div className="flex h-min w-32 flex-col items-center justify-center">
                <UploadIcon className=" h-16 w-24 fill-aw-gray-400" />
                <span className="block font-aw-sans text-sm font-medium italic text-aw-gray-400">
                  Upload Image
                </span>
              </div>
            </button>
          </div>
        </div>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40"
          onClose={
            selectedImageData?.isUploadedImage
              ? () => setUnsavedCloseOpen(true)
              : setOpen
          }
        >
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-5xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      {/* cover the component whe uploadImageState.loading === true */}
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="font-aw-serif text-2xl font-medium text-aw-blue-900">
                            Please choose or upload an image for:
                            <span className="font-bold"> {imageType}</span>
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-aw-blue-600 focus:ring-offset-2"
                              onClick={() =>
                                selectedImageData?.isUploadedImage
                                  ? setUnsavedCloseOpen(true)
                                  : setOpen(false)
                              }
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6 pb-20">
                        <div>
                          <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                              Select a tab
                            </label>
                            <select
                              id="tabs"
                              name="tabs"
                              className="block w-full rounded-md border-gray-300 focus:border-aw-blue-600 focus:ring-aw-blue-600"
                              defaultValue={selectedMethod}
                              onChange={(e) => {
                                const selectedTab = tabs.find(
                                  (tab) => tab.name === e.target.value
                                );
                                setSelectedMethod(selectedTab.name);
                              }}
                            >
                              {tabs.map((tab) => (
                                <option key={tab.name} className="">
                                  {tab.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="hidden sm:block">
                            <div className="border-b border-gray-200">
                              <nav className="-mb-px flex" aria-label="Tabs">
                                {tabs.map((tab) => (
                                  <span
                                    key={tab.name}
                                    className={classNames(
                                      tab.name === selectedMethod
                                        ? 'border-aw-blue-600 text-aw-blue-600'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                      'w-full cursor-pointer border-b-2 py-4 px-1 text-center font-aw-sans text-sm font-bold'
                                    )}
                                    aria-current={
                                      tab.name === selectedMethod
                                        ? 'page'
                                        : undefined
                                    }
                                    onClick={() => setSelectedMethod(tab.name)}
                                  >
                                    {tab.name}
                                  </span>
                                ))}
                              </nav>
                            </div>
                          </div>
                        </div>
                        <div className="mt-8">
                          {selectedMethod === 'Browse' ? (
                            // replace with galleryData from props
                            galleryDataLoading || !gallery.length ? (
                              <div className="flex w-full flex-col items-center justify-center ">
                                <P className="mt-8 font-aw-sans text-4xl text-aw-gray-400">
                                  No Images Found
                                </P>
                                <P className="mt-4 text-aw-gray-700">
                                  Please upload an image{' '}
                                </P>
                              </div>
                            ) : (
                              <>
                                <div className="flex justify-center mb-4">
                                  {Array.from(Array(totalPages).keys()).map(
                                    (page) => (
                                      <button
                                        key={page}
                                        onClick={() => setCurrentPage(page + 1)}
                                        className={classNames(
                                          'mx-2 py-1 px-2 rounded-md',
                                          currentPage === page + 1
                                            ? 'bg-aw-gray-200 text-aw-gray-900'
                                            : 'text-aw-gray-500 hover:text-aw-gray-900'
                                        )}
                                      >
                                        {page + 1}
                                      </button>
                                    )
                                  )}
                                </div>
                                <div className="grid grid-cols-fill-72 gap-3">
                                  {gallery.map((image) => {
                                    return (
                                      <div
                                        key={image.id}
                                        onClick={() =>
                                          handleGallerySelect(image)
                                        }
                                        className="flex cursor-pointer flex-col items-center justify-center rounded-md border p-2 shadow-md hover:shadow-xl"
                                      >
                                        <img
                                          src={`${image.url}?fit=pad&w=300&h=200&q=50`}
                                          alt={image.fileName}
                                        />
                                        <span className="inline-block w-full text-center font-aw-sans text-sm font-medium italic text-gray-900">
                                          {image.fileName}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="flex justify-center mt-4">
                                  {Array.from(Array(totalPages).keys()).map(
                                    (page) => (
                                      <button
                                        key={page}
                                        onClick={() => setCurrentPage(page + 1)}
                                        className={classNames(
                                          'mx-2 py-1 px-2 rounded-md',
                                          currentPage === page + 1
                                            ? 'bg-aw-gray-200 text-aw-gray-900'
                                            : 'text-aw-gray-500 hover:text-aw-gray-900'
                                        )}
                                      >
                                        {page + 1}
                                      </button>
                                    )
                                  )}
                                </div>
                              </>
                            )
                          ) : (
                            <form
                              onSubmit={(e) => handleUpload(e)}
                              className="flex flex-col items-center justify-center"
                            >
                              {showSizeError && (
                                <div className="rounded-md bg-red-50 p-4">
                                  <div className="flex">
                                    <div className="flex-shrink-0">
                                      <XCircleIcon
                                        className="h-5 w-5 text-red-400"
                                        aria-hidden="true"
                                      />
                                    </div>
                                    <div className="ml-3">
                                      <h3 className="text-sm font-medium text-red-800">
                                        Images must be less than 10MB
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="flex flex-col items-center justify-center">
                                <div className="flex flex-col">
                                  {selectedImageData?.isUploadedImage ? (
                                    <div>
                                      {isCropVisible ? (
                                        <Cropper
                                          image={cimage}
                                          crop={crop}
                                          zoom={zoom}
                                          aspect={aspectRatio}
                                          onCropChange={setCrop}
                                          onCropComplete={onCropComplete}
                                          onZoomChange={setZoom}
                                        />
                                      ) : (
                                        <div>
                                          <div className="cropped-image-container">
                                            {isCropSaved ? (
                                              <img
                                                className="cropped-image"
                                                src={croppedImage}
                                                alt="cropped"
                                              />
                                            ) : (
                                              <img
                                                src={selectedImageData?.src}
                                                alt="preview"
                                              />
                                            )}
                                          </div>
                                        </div>
                                      )}
                                      <span className="inline-block w-full text-center font-aw-sans text-sm font-medium italic text-gray-900">
                                        {selectedImageData?.fileName}
                                      </span>
                                    </div>
                                  ) : (
                                    <div>
                                      <p className="font-aw-sans text-aw-gray-700 text-xl mt-6">
                                        Click{' '}
                                        <span className="italic">
                                          Choose Image
                                        </span>{' '}
                                        below to select an image from your
                                        device
                                      </p>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className={`flex w-full ${
                                    selectedImageData?.isUploadedImage
                                      ? 'justify-around'
                                      : 'justify-center'
                                  } fixed border-t bg-white shadow-lg right-0 bottom-0`}
                                >
                                  <label
                                    htmlFor="file-upload"
                                    className="relative py-5 cursor-pointer"
                                  >
                                    <span className="flex h-[55px] items-center rounded bg-white py-2 px-3 font-aw-sans font-bold text-aw-gray-300 outline outline-2 outline-aw-gray-300 hover:opacity-90 lg:py-4 lg:px-6">
                                      Choose Image
                                    </span>
                                    <input
                                      id="file-upload"
                                      name="file-upload"
                                      type="file"
                                      accept=".jpg,.jpeg,.png,.svg"
                                      className="sr-only"
                                      onChange={handleFileChange}
                                    />
                                  </label>

                                  {selectedImageData?.isUploadedImage ? (
                                    <div className="flex mt-4">
                                      <ButtonTertiary
                                        className=""
                                        onClick={showCrop}
                                      >
                                        {isCropVisible
                                          ? 'Cancel Crop'
                                          : 'Crop Image'}
                                      </ButtonTertiary>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  <div className="mt-4">
                                    {selectedImageData?.isUploadedImage ? (
                                      <div>
                                        {isCropVisible ? (
                                          <ButtonPrimary
                                            className="min-w-[150px] justify-center flex"
                                            onClick={getCroppedImage}
                                          >
                                            Save Crop
                                          </ButtonPrimary>
                                        ) : (
                                          <ButtonPrimary
                                            type="submit"
                                            className="min-w-[150px] justify-center flex"
                                          >
                                            Save
                                          </ButtonPrimary>
                                        )}
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </form>
                          )}
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default ImageUploadOrSelect;
