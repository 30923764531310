import React from 'react';

function ButtonSecondary({
  children,
  onClick,
  type = 'button',
  disabled = false,
  className = '',
  ...rest
}) {
  return (
    <div {...rest}>
      <button
        onClick={onClick}
        disabled={disabled}
        type={type}
        className={`flex h-[55px] items-center shadow-md rounded bg-white py-2 px-3 font-aw-sans font-bold text-aw-gray-300 border-2 border-aw-gray-300 hover:opacity-90 lg:py-4 lg:px-6 ${className} disabled:bg-gray-100 disabled:border-gray-200`}
      >
        {children}
      </button>
    </div>
  );
}

export default ButtonSecondary;
