import React from 'react';
import { useLocation } from 'react-router-dom';

function GeneratorBreadcrumbs() {
  const location = useLocation();
  const pathname = location.pathname;
  const steps = {
    overview: {
      name: 'Overview & Rooms',
      path: 'overview',
    },
    cabinets: {
      name: 'Cabinet Levels',
      path: 'cabinets',
    },
    hardware: {
      name: 'Hardware Levels',
      path: 'hardware',
    },
    recommendations: {
      name: 'Recommended Products',
      path: 'recommendations',
    },
    highlights: {
      name: 'Add-Ons',
      path: 'addons',
    },
    publish: {
      name: 'Review & Publish',
      path: 'publish',
    },
  };

  return (
      <div className="relative flex h-36 w-full items-center justify-between bg-aw-blue-600 px-10 lg:px-20">
        {Object.keys(steps).map((step) => {
          const currentStep = steps[step];

          return (
            <div
              key={currentStep.name}
              className="flex flex-col items-center z-10"
            >
              <div
                className={`h-8 w-8 rounded-full  ${
                  pathname.endsWith(currentStep.path)
                    ? ' border-1 border bg-aw-blue-400'
                    : 'bg-white'
                }`}
              />
              <div className="absolute bottom-4 flex h-8 w-24 items-start justify-center lg:w-auto">
                <span className="inline-block text-center font-aw-serif text-xs text-white">
                  {currentStep.name}
                </span>
              </div>
            </div>
          );
        })}

        <div className="absolute top-0 left-0 h-full w-full bg-transparent px-[9%] xl:px-[7%] ">
          <div className="relative top-1/2 mx-auto h-0.5 w-full bg-aw-gray-400"></div>
        </div>
      </div>
  
  );
}

export default GeneratorBreadcrumbs;
