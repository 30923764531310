import React from 'react';

function P(props) {
  return (
    <p className={`${props.className} font-aw-sans text-base text-aw-blue-900`}>
      {props.children}
    </p>
  );
}

export default P;
