import * as React from 'react';

const UploadIcon = (props) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox='0 0 68 68'
    {...props}
  >
    <path
      d="M68.641 43.33a1.304 1.304 0 1 1-1.63 2.036l-4.402-3.521v10.33a1.304 1.304 0 1 1-2.609 0v-10.33l-4.403 3.521a1.304 1.304 0 1 1-1.629-2.036l6.522-5.218a1.304 1.304 0 0 1 1.63 0l6.521 5.218Zm7.012 2.323a14.343 14.343 0 0 1-28.447 2.608H3.913A3.918 3.918 0 0 1 0 44.348V3.913A3.917 3.917 0 0 1 3.913 0h54.783a3.917 3.917 0 0 1 3.913 3.913v27.458a14.363 14.363 0 0 1 13.044 14.282ZM2.609 3.913v39.653l18.773-23.468a2.609 2.609 0 0 1 4.16.113l7.07 9.246 7.175-7.175a2.555 2.555 0 0 1 1.989-.76 2.61 2.61 0 0 1 1.892.975l10.023 11.016a14.231 14.231 0 0 1 6.31-2.142V3.913a1.306 1.306 0 0 0-1.305-1.304H3.913A1.306 1.306 0 0 0 2.61 3.913Zm20.836 17.848L4.28 45.653h37.428L23.445 21.76Zm23.512 23.892a14.298 14.298 0 0 1 4.655-10.549L41.685 24.19l-7.471 7.363 10.779 14.1h1.964Zm26.087 0a11.74 11.74 0 1 0-11.74 11.739 11.752 11.752 0 0 0 11.74-11.74ZM41.739 11.739a6.522 6.522 0 1 1 6.522 6.522 6.53 6.53 0 0 1-6.522-6.522Zm2.61 0a3.913 3.913 0 1 0 7.825 0 3.913 3.913 0 0 0-7.826 0Z"
      fill="#B6B9BF"
    />
    <path
      d="M68.641 43.33a1.304 1.304 0 1 1-1.63 2.036l-4.402-3.521v10.33a1.304 1.304 0 1 1-2.609 0v-10.33l-4.403 3.521a1.304 1.304 0 1 1-1.629-2.036l6.522-5.218a1.304 1.304 0 0 1 1.63 0l6.521 5.218Zm7.012 2.323a14.343 14.343 0 0 1-28.447 2.608H3.913A3.918 3.918 0 0 1 0 44.348V3.913A3.917 3.917 0 0 1 3.913 0h54.783a3.917 3.917 0 0 1 3.913 3.913v27.458a14.363 14.363 0 0 1 13.044 14.282ZM2.609 3.913v39.653l18.773-23.468a2.609 2.609 0 0 1 4.16.113l7.07 9.246 7.175-7.175a2.555 2.555 0 0 1 1.989-.76 2.61 2.61 0 0 1 1.892.975l10.023 11.016a14.231 14.231 0 0 1 6.31-2.142V3.913a1.306 1.306 0 0 0-1.305-1.304H3.913A1.306 1.306 0 0 0 2.61 3.913Zm20.836 17.848L4.28 45.653h37.428L23.445 21.76Zm23.512 23.892a14.298 14.298 0 0 1 4.655-10.549L41.685 24.19l-7.471 7.363 10.779 14.1h1.964Zm26.087 0a11.74 11.74 0 1 0-11.74 11.739 11.752 11.752 0 0 0 11.74-11.74ZM41.739 11.739a6.522 6.522 0 1 1 6.522 6.522 6.53 6.53 0 0 1-6.522-6.522Zm2.61 0a3.913 3.913 0 1 0 7.825 0 3.913 3.913 0 0 0-7.826 0Z"
      fill="#030229"
      fillOpacity={0.05}
    />
  </svg>
);

export default UploadIcon;
