import * as React from 'react';

const SwatchesIcon = ({className}) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 256 256"
    className={`w-6 ${className}`}
  >
    <path d="M72,188a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,72,188Zm152-24.49512V208a12.01343,12.01343,0,0,1-12,12H72c-.009,0-.01709-.00256-.02612-.00269l-.03.00074A39.30361,39.30361,0,0,1,39.79,203.31738,41.26669,41.26669,0,0,1,32.731,172.35449L56.918,35.18164a11.99939,11.99939,0,0,1,13.90234-9.73437l55.14844,9.72363a12.00023,12.00023,0,0,1,9.73389,13.90234l-12.27979,69.64307L189.876,94.5293a12.01346,12.01346,0,0,1,15.38037,7.17187l19.15332,52.623A11.96147,11.96147,0,0,1,224,163.50488ZM121.81885,127.81372l-10.42627,59.13159a39.482,39.482,0,0,1-5.95947,14.93982L214.50146,162.1875a4.00518,4.00518,0,0,0,2.39063-5.12695L197.73877,104.4375a4.00285,4.00285,0,0,0-5.12695-2.39063ZM67.06006,211.61621a32.14833,32.14833,0,0,0,36.45361-26.05957l24.31055-137.873a3.99979,3.99979,0,0,0-3.24463-4.63379L69.43115,33.32617a3.9962,3.9962,0,0,0-4.63476,3.24512L40.60986,173.74316A33.21514,33.21514,0,0,0,46.29932,198.666,31.3708,31.3708,0,0,0,67.06006,211.61621ZM216,208V170.15576L101.0332,212H212A4.00427,4.00427,0,0,0,216,208Z"/>
  </svg>
);

export default SwatchesIcon;



