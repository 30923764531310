import React from 'react';
import { Droppable } from '@hello-pangea/dnd';

function LevelSelectorDroppable({ id, children }) {
  return (
    <Droppable droppableId={id}>
      {(provided, snapshot) => (
        <div
          // fixed width makes sense for this horizontally overflowing component
          className={`min-w-[17rem] w-60 ${
            snapshot.isDraggingOver ? 'bg-aw-gray-200' : 'bg-aw-gray-100'
          }`}
          ref={provided.innerRef}
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default LevelSelectorDroppable;
