import { useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import slugify from 'slugify';
import { REMOVE_PREVIEW_URL } from '../../constants/contentfulActions';
import {
  NETLIFY_PREVIEW,
  NETLIFY_PUBLISH,
  NETLIFY_PUBLISH_RENAMED,
} from '../../constants/netlifyActions';
import { GET_SINGLE_COMMUNITY } from '../../graphql/queries/communities';
import { useContentful } from '../../hooks/useContentful';
import useNetlifyActions from '../../hooks/useNetlifyActions';
import ButtonSecondary from '../buttons/ButtonSecondary';
import ButtonTertiary from '../buttons/ButtonTertiary';
import PreviewIcon from '../icons/PreviewIcon';
import PublishStatusModal from '../modals/PublishStatusModal';
import netlifyIdentity from 'netlify-identity-widget';

function GeneratorPublishMenu({ prevRoute, publishDisabled }) {
  const { communityData, initialCommunityName } = useOutletContext();
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [newPublishedUrl, setNewPublishedUrl] = useState(null);
  const [newPreviewUrl, setNewPreviewUrl] = useState(null);
  const [publishComplete, setPublishComplete] = useState(false);
  const [previewComplete, setPreviewComplete] = useState(false);
  const [dispatchRemovePreviewURL] = useContentful();
  const { refetch } = useQuery(GET_SINGLE_COMMUNITY, {
    variables: { id: communityData.id },
  });

  const navigate = useNavigate();

  const retryRefetchPreview = (resolve, retryCount = 0, maxRetries = 10) => {
    refetch().then((updatedData) => {
      const previewUrl = updatedData.data.community.previewUrl;

      if (previewUrl || retryCount >= maxRetries) {
        resolve(updatedData);
      } else {
        setTimeout(() => {
          retryRefetchPreview(resolve, retryCount + 1);
        }, 2000);
      }
    });
  };

  const retryRefetchPublish = (resolve, retryCount = 0, maxRetries = 10) => {
    refetch().then((updatedData) => {
      const publishedUrl = updatedData.data.community.publishedUrl;

      if (publishedUrl || retryCount >= maxRetries) {
        resolve(updatedData);
      } else {
        setTimeout(() => {
          retryRefetchPublish(resolve, retryCount + 1);
        }, 2000);
      }
    });
  };

  const onPreviewCompleted = () => {
    new Promise((resolve) => {
      retryRefetchPreview(resolve);
    }).then((updatedData) => {
      const url = updatedData.data.community.previewUrl;
      if (url) {
        setNewPreviewUrl(url);
        setPreviewComplete(true);
      }
    });
  };

  const onPublishCompleted = () => {
    new Promise((resolve) => {
      retryRefetchPublish(resolve);
    }).then((updatedData) => {
      const url = updatedData.data.community.publishedUrl;
      if (url) {
        setNewPublishedUrl(url);
        setPublishComplete(true);
      }
    });
  };

  const { deployStatus: previewDeployStatus, deploy: previewDeploy } =
    useNetlifyActions(onPreviewCompleted);
  const { deployStatus: publishDeployStatus, deploy: publishDeploy } =
    useNetlifyActions(onPublishCompleted);

  const handlePreviewDeploy = async () => {
    await netlifyIdentity.refresh();
    const data = {
      type: NETLIFY_PREVIEW,
      communityId: communityData.id,
    };
    await previewDeploy(data, refetch);
  };

  const handleUpdateOrPublishDeploy = async () => {
    await netlifyIdentity.refresh();
    let type;
    if (
      !!communityData.publishedUrl &&
      communityData.publishedUrl.endsWith(
        `${slugify(initialCommunityName, { lower: true, strict: true })}/`
      )
    ) {
      type = NETLIFY_PUBLISH_RENAMED;
    } else type = NETLIFY_PUBLISH;

    const data = {
      type,
      communityId: communityData.id,
      oldCommunityName: initialCommunityName,
      publish: true,
    };

    await publishDeploy(data, refetch);
  };

  useEffect(() => {
    if (publishDeployStatus.loading) {
      setShowPublishModal(true);
    }
  }, [publishDeployStatus.loading]);

  useEffect(() => {
    if (previewDeployStatus.loading) {
      setShowPreviewModal(true);
    }

    if (previewDeployStatus.error && !previewDeployStatus.loading) {
      dispatchRemovePreviewURL({
        type: REMOVE_PREVIEW_URL,
        payload: {
          communityId: communityData.id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewDeployStatus.loading, previewDeployStatus.error]);

  return (
    <>
      <PublishStatusModal
        show={showPublishModal}
        setShow={setShowPublishModal}
        loadType="publish"
        url={newPublishedUrl}
        error={publishDeployStatus.error}
        publishComplete={publishComplete}
        communityName={communityData.name}
      />
      <PublishStatusModal
        show={showPreviewModal}
        setShow={setShowPreviewModal}
        loadType="preview"
        url={newPreviewUrl}
        error={previewDeployStatus.error}
        publishComplete={previewComplete}
        communityName={communityData.name}
      />
      <div className="fixed flex justify-center bottom-0 left-0 right-0 bg-white py-5 px-8 border-t">
        <div className="flex justify-between max-w-[1472px] w-full">
          {prevRoute ? (
            <ButtonSecondary onClick={() => navigate(prevRoute)}>
              Previous
            </ButtonSecondary>
          ) : (
            <div></div>
          )}

          <div className="flex gap-8">
            {!communityData.previewUrl && (
              <ButtonSecondary
                disabled={publishDisabled}
                onClick={() => handlePreviewDeploy()}
              >
                <span className="mr-2">
                  <PreviewIcon className="fill-aw-gray-300 w-6" />
                </span>{' '}
                Preview Microsite
              </ButtonSecondary>
            )}
            <ButtonTertiary
              disabled={publishDisabled}
              onClick={() => handleUpdateOrPublishDeploy()}
            >
              {!communityData.isPublished ? `Publish` : 'Update'} Microsite
            </ButtonTertiary>
          </div>
        </div>
      </div>
      {previewDeployStatus.error && (
        <div className="text-red-600">
          Error: Failed to deploy preview. Please try again.
        </div>
      )}
      {publishDeployStatus.error && (
        <div className="text-red-600">
          Error: Failed to publish microsite. Please try again.
        </div>
      )}
    </>
  );
}

export default GeneratorPublishMenu;
