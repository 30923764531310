import React from 'react';

function A(props) {
  return (
    <a
      href={props.href}
      className={`${props.className} font-aw-sans text-base text-aw-gray-700 hover:cursor-pointer hover:underline`}
    >
      {props.children}
    </a>
  );
}

export default A;
