import React, { useState, useEffect } from 'react';
import { P } from '../../components/typography';
import SquarePlusIcon from '../../components/icons/SquarePlusIcon';
import { useOutletContext, useParams } from 'react-router-dom';
import {
  GeneratorRecommendationCard,
  GeneratorNavigation,
  GeneratorHeader,
} from '../../components/generator';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../../components/LoadingSpinner';
import Error from '../../components/Error';
import { SizeConstrained } from '../../components/layout/Layout';
import { useContentful } from '../../hooks/useContentful';
import { CREATE_RECOMMENDATION } from '../../constants/contentfulActions';
import { GET_SELECTED_PRODUCTS } from '../../graphql/queries/products';
import LoaderModal from '../../components/modals/LoaderModal';

function GeneratorRecommendations() {
  const { communityData, setCommunityData, isCommunityLoaded } =
    useOutletContext();
  const [dispatchCreateNewRecommendation, newRecommendationState] =
    useContentful();
  const [validationErrors, setValidationErrors] = useState({});
  const { id } = useParams();
  const [productsToInclude] = useState(communityData.uniqueCabinetSelections);
  const {
    loading,
    error,
    data: selectedProductsData,
  } = useQuery(GET_SELECTED_PRODUCTS, {
    variables: { ids: productsToInclude },
  });

  const validateFields = () => {
    const errors = {
      recommendations: {},
    };

    communityData.recommendations.forEach((recommendation, index) => {
      // Initialize a sub-object for the current room if it doesn't already exist
      if (!errors.recommendations[recommendation.id]) {
        errors.recommendations[recommendation.id] = {
          image: null,
          product: null,
        };
      }

      if (recommendation.image.id === null) {
        errors.recommendations[
          recommendation.id
        ].image = `Please select a lifestyle image`;
      }

      if (recommendation.product.id === null) {
        errors.recommendations[
          recommendation.id
        ].product = `Please select a product`;
      }
    });

    setValidationErrors(errors);

    const hasErrors = Object.values(errors.recommendations).some(
      (recommendationErrors) =>
        Object.values(recommendationErrors).some((error) => !!error)
    );
    return !hasErrors;
  };

  useEffect(() => {
    if (newRecommendationState.success && newRecommendationState.data) {
      const recommendations = [
        ...(communityData.recommendations ?? []),
        // adds empty recommendation to the end of the array
        {
          id: newRecommendationState.data?.sys?.id,
          image: { id: null, url: null },
          product: { id: null },
        },
      ];
      setCommunityData({
        ...communityData,
        recommendations: recommendations,
      });
    }
    // From @sonja - This could be done better, but this is the best way to limit the
    // useEffect to only run when the newRecommendationState changes currently.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newRecommendationState.success, newRecommendationState.data]);

  const handleNewRecommendation = () => {
    dispatchCreateNewRecommendation({
      type: CREATE_RECOMMENDATION,
      payload: {
        newRecoCommunityId: id,
      },
    });
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <Error />;

  return (
    <>
      <LoaderModal show={newRecommendationState.loading} />
      <SizeConstrained>
        <GeneratorHeader title="Optional: Recommended Products" />
        <P>
          Select up to three recommended cabinetry options to promote in your
          community. Recommended products will be highlighted on the community
          home page, as well as on any room page where the product is available.
        </P>
        {communityData.recommendations.length > 0 && (
          <div>
            {communityData.recommendations.map((recommendation, index) => (
              <GeneratorRecommendationCard
                currentRecommendation={recommendation}
                key={`${recommendation.id}-${index}`}
                index={index}
                products={selectedProductsData?.productCollection?.items}
                communityId={id}
                errors={validationErrors.recommendations?.[recommendation?.id]}
              />
            ))}
          </div>
        )}

        {(communityData.recommendations.length < 2 ||
          !communityData.recommendations.length ||
          communityData.recommendations.every(
            (recommendation) => recommendation !== ''
          )) &&
          productsToInclude.length !== communityData.recommendations.length && (
            <div className="mt-8 flex justify-start">
              <div
                className="flex cursor-pointer"
                onClick={handleNewRecommendation} // this should add a new recommendation to the communityData
              >
                <span className="font-aw-sans text-aw-gray-700">
                  Add {communityData.recommendations.length ? 'another' : ''}{' '}
                  recommended product
                </span>
                <SquarePlusIcon className="ml-2 h-10 w-10 fill-aw-gray-700" />
              </div>
            </div>
          )}
        <GeneratorNavigation
          nextText="Define Add-Ons"
          nextRoute={`/generator/${id}/addons`}
          prevRoute={`/generator/${id}/hardware`}
          validateFields={validateFields}
          dataLoaded={isCommunityLoaded}
        />
      </SizeConstrained>
    </>
  );
}

export default GeneratorRecommendations;
