import { ArrowsUpDownIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { useLocation, useOutletContext, useParams } from 'react-router-dom';
import FieldLabel from '../../components/forms/FieldLabel';
import TextInput from '../../components/forms/TextInput';
import {
  GeneratorHeader,
  GeneratorSubheader,
  GeneratorBuilderCard,
  GeneratorRoomCard,
  GeneratorNavigation,
} from '../../components/generator';
import SquarePlusIcon from '../../components/icons/SquarePlusIcon';
import ImageUploadOrSelect from '../../components/ImageUploadOrSelect';
import H2 from '../../components/typography/H2';
import { SizeConstrained } from '../../components/layout/Layout';
import { useContentful } from '../../hooks/useContentful';
import { CREATE_ROOM } from '../../constants/contentfulActions';
import { GET_COMMUNITIES_BY_BUILDER } from '../../graphql/queries/communities';
import { useQuery } from '@apollo/client';
import {
  ALLOWED_ROOMS_LIMIT,
  checkCommunityNameUnique,
  extractCommunityNamesFromBuilders,
} from '../../graphql/helpers';
import ErrorMessage from '../../components/ErrorMessage';
import LoaderModal from '../../components/modals/LoaderModal';
import Speedbump from '../../components/modals/Speedbump';

function GeneratorOverview() {
  const { id } = useParams();
  const {
    communityData,
    setCommunityData,
    refetchCommunityData,
    initialCommunityName,
    builderImageTag,
    isCommunityLoaded,
  } = useOutletContext();
  const { state: locationState } = useLocation();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [roomLimitAlert, setRoomLimitAlert] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    rooms: {},
    banner: null,
    name: null,
  });
  const [dispatchAddNewRoom, addNewRoomState] = useContentful();
  const { loading: builderLoading, data: builderCommunitiesData } = useQuery(
    GET_COMMUNITIES_BY_BUILDER,
    {
      variables: { builderId: communityData?.builder?.id },
    }
  );

  const currentExistingCommunityNames = extractCommunityNamesFromBuilders(
    builderCommunitiesData
  );
  const isCommunityNameValid = checkCommunityNameUnique(
    communityData.name,
    currentExistingCommunityNames,
    initialCommunityName
  );

  const checkNestedErrors = (obj) => {
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        if (checkNestedErrors(obj[key])) {
          return true;
        }
      } else if (obj[key] !== null && obj[key] !== '') {
        return true;
      }
    }
    return false;
  };

  const validateFields = () => {
    const errors = {
      rooms: {},
      banner: null,
      name: null,
    };

    if (!communityData.name) {
      errors.name = 'Please enter a community name';
    }

    if (!communityData.banner.id) {
      errors.banner = 'Please select a banner image';
    }

    if (!isCommunityNameValid) {
      errors.name =
        'Community name already exists for this builder. Please choose a different one.';
    }

    communityData.rooms.forEach((room, index) => {
      if (!errors.rooms[room.id]) {
        errors.rooms[room.id] = {};
      }
      if (!room.name) {
        errors.rooms[room.id].name = `Please enter a room name`;
      }

      if (!room.image.id) {
        errors.rooms[room.id].image = `Please select a room image`;
      }

      if (!room.headerImage.id) {
        errors.rooms[room.id].headerImage = `Please select a header image`;
      }

      if (room.products.length === 0) {
        errors.rooms[room.id].products = `Please select at least one product`;
      }
    });

    setValidationErrors(errors);

    const hasErrors = checkNestedErrors(errors);

    return !hasErrors;
  };

  useEffect(() => {
    if (!builderLoading && isCommunityLoaded) {
      setCommunityData({
        ...communityData,
        builderCurrentCommunities: builderCommunitiesData,
      });

      setDataLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [builderLoading, isCommunityLoaded]);

  useEffect(() => {
    if (addNewRoomState.success) {
      setCommunityData((prevState) => ({
        ...prevState,
        rooms: [
          ...prevState.rooms,
          {
            id: addNewRoomState.data.sys.id,
            name: '',
            image: {
              title: null,
              id: null,
              fileName: null,
              url: null,
            },
            headerImage: {
              title: null,
              id: null,
              fileName: null,
              url: null,
            },
            products: [],
          },
        ],
      }));
    }
  }, [addNewRoomState.success, addNewRoomState.data, setCommunityData]);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newRoomData = Array.from(communityData['rooms']);
    const [reorderedItem] = newRoomData.splice(result.source.index, 1);
    newRoomData.splice(result.destination.index, 0, reorderedItem);
    setCommunityData({ ...communityData, rooms: newRoomData });
  };

  const handleCommunityNameChange = (e) => {
    setCommunityData({ ...communityData, name: e.target.value });
  };

  const handleSetBannerImage = (image) => {
    setCommunityData({ ...communityData, banner: image });
  };

  const handleAddNewRoom = async () => {
    if (communityData.rooms.length < ALLOWED_ROOMS_LIMIT) {
      await dispatchAddNewRoom({
        type: CREATE_ROOM,
        payload: {
          communityId: communityData.id,
          roomName: '',
        },
      });
    } else {
      setRoomLimitAlert(true);
    }
  };

  return (
    <>
      {/* TODO: This speedbump and corresponding logic should be adjusted/removed when the room limit is increased. */}
      <Speedbump
        warning={true}
        open={roomLimitAlert}
        setOpen={setRoomLimitAlert}
        onConfirm={() => setRoomLimitAlert(false)}
        onClose={() => setRoomLimitAlert(false)}
        confirmBtnText="OK"
        confirmBody={`Please limit your microsite to ${ALLOWED_ROOMS_LIMIT} or fewer rooms.`}
      />
      <LoaderModal show={addNewRoomState.loading} />
      <SizeConstrained>
        <GeneratorHeader
          title={
            locationState
              ? `Edit ${
                  communityData.name ? communityData.name : 'New'
                } Microsite`
              : `Add A New Community Microsite`
          }
        />
        <GeneratorSubheader title="Community Overview" />
        <div className="flex w-full gap-12">
          <div className="w-full relative">
            <TextInput
              label="Community name:"
              value={communityData.name}
              onChange={handleCommunityNameChange}
            />
            <ErrorMessage show={!isCommunityNameValid}>
              Community name already exists for this builder. Please choose a
              different one.
            </ErrorMessage>
            <ErrorMessage show={validationErrors.name}>
              {validationErrors.name}
            </ErrorMessage>
          </div>
          <div className="w-full">
            <FieldLabel>Builder:</FieldLabel>
            <GeneratorBuilderCard
              builder={communityData.builder}
              isSelected={true}
            />
          </div>
        </div>
        <div className="border-b py-12">
          <FieldLabel>
            Add banner image:{' '}
            <span className="ml-4 inline text-xs text-aw-gray-300">
              3080x840 recommended
            </span>
          </FieldLabel>
          {/* replace galleryData when we have gallery data */}
          <ImageUploadOrSelect
            imageType="Banner Image"
            setImage={handleSetBannerImage}
            currentImage={communityData?.banner ?? null}
            targetEntryId={communityData.id}
            tagsToAddToUploads={[builderImageTag, 'hero']}
            imageTagToQuery="hero"
          />
          <ErrorMessage show={validationErrors.banner !== null}>
            {validationErrors.banner}
          </ErrorMessage>
        </div>
        <div className="my-12">
          <H2>Define Rooms</H2>
          <p className="mt-6 font-aw-serif">
            The order of rooms indicates the order they will show up in your
            microsite, with the top rooms listed first.{' '}
          </p>
          <div className="mt-2 flex items-center">
            <div className="inline-block pr-2">
              <ArrowsUpDownIcon className="h-6 w-6 fill-aw-gray-700" />
            </div>
            <span className="font-aw-serif">
              Drag and drop rooms to reorder.
            </span>
          </div>

          <div>
            {communityData.rooms.length ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="room-list">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {communityData.rooms.map((room, index) => (
                        <Draggable
                          key={room.id}
                          draggableId={room.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className="relative"
                            >
                              <div
                                {...provided.dragHandleProps}
                                className="absolute cursor-grab active:cursor-grabbing right-0 top-8 rounded-lg border p-1 hover:shadow-lg"
                              >
                                <ArrowsUpDownIcon className="h-8 w-8 fill-aw-gray-700" />
                              </div>
                              <GeneratorRoomCard
                                currentRoom={room}
                                index={index}
                                refetch={refetchCommunityData}
                                communityId={id}
                                errors={validationErrors.rooms[room?.id]}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <></>
            )}
          </div>
          <div className="mt-8 flex">
            <div
              className="flex cursor-pointer"
              onClick={() => handleAddNewRoom()}
            >
              <span className="font-aw-sans text-aw-gray-700">
                Add {communityData.rooms.length ? 'another' : ''} room
              </span>
              <SquarePlusIcon className="ml-2 h-10 w-10 fill-aw-gray-700" />
            </div>
          </div>
        </div>
        <GeneratorNavigation
          nextText="Define Cabinet Levels"
          nextRoute={`/generator/${id}/cabinets`}
          validateFields={validateFields}
          dataLoaded={dataLoaded}
        />
      </SizeConstrained>
    </>
  );
}

export default GeneratorOverview;
