import * as React from 'react';

const DeleteIcon = (props) => (
  <svg width={18} height={22} xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
    <path d="M16 6a1 1 0 0 0-1 1v11.19a1.92 1.92 0 0 1-2.01 1.809H5.01A1.92 1.92 0 0 1 3 18.189V7a1 1 0 0 0-2 0v11.19A3.918 3.918 0 0 0 5.01 22h7.98a3.918 3.918 0 0 0 4.01-3.81V7a1 1 0 0 0-1-1ZM17 3h-4V1a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v2H1a1 1 0 1 0 0 2h16a1 1 0 1 0 0-2ZM7 3V2h4v1H7Z" />
    <path d="M8 16V9a1 1 0 0 0-2 0v7a1 1 0 1 0 2 0ZM12 16V9a1 1 0 1 0-2 0v7a1 1 0 0 0 2 0Z" />
  </svg>
);

export default DeleteIcon;
