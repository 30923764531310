import { Fragment, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import logoImg from '../assets/images/logo.png';
import AccountIcon from '../components/icons/AccountIcon';
import netlifyIdentity from 'netlify-identity-widget';

// TODO: replace with real data
const menuItems = [
  {
    name: 'My communities',
    href: '/',
  },
  {
    name: 'Help',
    href: 'https://watson.timberlake.com/timberlake-microsite-tool/',
    icon: ArrowTopRightOnSquareIcon,
    external: true,
  },
  {
    name: 'Log out',
    href: '/',
    onClick: netlifyIdentity.logout,
  },
];

export default function HeaderMenu({currentUser}) {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return currentUser ? (
    <div
      className={`flex w-full z-20 fixed top-0 items-center justify-between p-6 shadow-md md:space-x-10 bg-white ${
        isScrolled ? 'h-16' : 'h-24'
      } transition-all duration-300`}
    >
      <div className="flex justify-start lg:w-0 lg:flex-1">
        <a href="/">
          <span className="sr-only">Timberlake Cabinetry</span>
          <img
            className={`h-8 w-auto sm:h-10 transition-all duration-300 ${
              isScrolled ? 'h-6 sm:h-8' : 'h-8 sm:h-10'
            }`}
            src={logoImg}
            alt=""
          />
        </a>
      </div>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button className="group inline-flex items-center rounded-md bg-white text-base font-medium  focus:outline-none">
              <span>{currentUser.email}</span>
              <AccountIcon
                className={`ml-4 transition-all duration-300 ${
                  isScrolled ? 'h-6 w-6' : 'h-8 w-8'
                }`}
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute top-full left-1/4 z-20 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0">
                <div className="overflow-hidden rounded-lg border border-black shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                    {menuItems.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-m-3 block rounded-md p-3 transition duration-150 ease-in-out hover:bg-gray-50"
                        target={item.external ? '_blank' : '_self'}
                        rel={item.external ? 'noopener noreferrer' : ''}
                        onClick={() => {
                          item.onClick();
                        }}
                      >
                        <p className="text-base font-medium text-gray-900">
                          {item.name}
                          {item.icon ? (
                            <item.icon
                              className="mb-1 ml-2 inline h-3 w-3 text-blue-500"
                              aria-hidden="true"
                            />
                          ) : null}
                        </p>
                      </a>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  ) : (
    <></>
  );
}
