import React from 'react'

function FieldLabel({children, htmlFor=""}) {
  return (
    <label
      htmlFor={htmlFor}
      className="text-md block font-aw-sans text-gray-700"
    >
      {children}
    </label>
  );
}

export default FieldLabel