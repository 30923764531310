import React, { useEffect } from 'react';
import ButtonTertiary from '../buttons/ButtonTertiary';
import H1 from '../typography/H1';
import SaveIcon from '../icons/SaveIcon.png';
import { useContentful } from '../../hooks/useContentful';
import { useOutletContext } from 'react-router-dom';
import { UPDATE_COMMUNITY } from '../../constants/contentfulActions';
import dayjs from 'dayjs';
import netlifyIdentity from 'netlify-identity-widget';
import LoaderModal from '../modals/LoaderModal';

function GeneratorHeader({ title }) {
  const { communityData, setDraftSaveCount } = useOutletContext();
  const [dispatchUpdateCommunity, updateCommunityState, resetState] =
    useContentful();
  const handleSaveDraft = () => {
    netlifyIdentity.refresh();
    /**
     * Because we pass draftSaveCount as a dependency to the useMemo hook
     * that sets the initialCommunityData in GeneratorNavigation,
     * we can prevent an unnecessary contentful save by incrementing it
     */
    setDraftSaveCount((prevCount) => prevCount + 1);
    dispatchUpdateCommunity({
      type: UPDATE_COMMUNITY,
      payload: {
        communityData,
      },
    });
  };

  useEffect(() => {
    if (updateCommunityState.success) {
      resetState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCommunityState.success]);
  return (
    <>
      <LoaderModal show={updateCommunityState.loading} />
      <div className="border-b-1 mb-12 flex justify-between border-b pb-10">
        <div>
          <H1>{title}</H1>
          <p className="font-aw-sans pt-4 text-gray-500">
            Last saved:{' '}
            {dayjs(communityData.publishedAt).format(
              'MMMM DD, YYYY - h:mm:ss a'
            )}
          </p>
        </div>
        <ButtonTertiary onClick={() => handleSaveDraft()}>
          Save draft
          <img src={SaveIcon} className="ml-3 w-5 -translate-y-1" alt="" />
        </ButtonTertiary>
      </div>
    </>
  );
}

export default GeneratorHeader;
