import { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { createClient } from 'contentful-management';

import { useOutletContext } from 'react-router-dom';
import {
  CREATE_COMMUNITY,
  CREATE_HARDWARE_LEVEL,
  CREATE_CABINET_LEVEL,
  CLONE_COMMUNITY,
  UPDATE_COMMUNITY,
  UPLOAD_IMAGE,
  CREATE_ROOM,
  CREATE_RECOMMENDATION,
  CREATE_ADD_ON,
  CREATE_USER,
  DELETE_COMMUNITY,
  DELETE_ROOM,
  DELETE_RECOMMENDATION,
  DELETE_ADD_ON,
  REMOVE_PREVIEW_URL,
  CREATE_UNCATEGORIZED_HARDWARE_LEVEL,
} from '../constants/contentfulActions';

import {
  removePreviewUrlHandler,
  updateCommunityHandler,
  createUserHandler,
  createCommunityHandler,
  createCabinetLevelHandler,
  uploadImageHandler,
  createRoomHandler,
  createHardwareLevelHandler,
  deleteRoomHandler,
  deleteRecommendationHandler,
  deleteAddOnHandler,
  deleteCommunityHandler,
  createAddOnHandler,
  cloneCommunityHandler,
  createRecommendationHandler,
  createUncategorizedHardwareLevelHandler,
} from './contentful-handlers';

const environmentId =
  process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID ?? 'master';
const spaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID ?? 'buvateexvkf2';

/**
 * Custom hook to interact with the Contentful Management API. See readme for more details.
 * @param {Object} initialState - Initial state for the hook
 * @returns {Array} - Array containing dispatch function, current state, and reset state function
 */
export function useContentful(
  initialState = { loading: false, error: null, success: false, data: null }
) {
  const { currentUser } = useOutletContext();
  const isMounted = useRef(true);
  const contentfulToken = useMemo(() => {
    return currentUser?.user_metadata?.contentfulManagementToken ?? null;
  }, [currentUser]);
  const client = contentfulToken
    ? createClient({
        accessToken: contentfulToken,
        rateLimit: 4,
        rateLimitPeriod: 1000,
        rateLimitRetryDelay: 200,
        throttle: 'auto',
      })
    : null;

  const [state, setState] = useState(initialState);
  const resetState = () => {
    setState(initialState);
  };

  const dispatchContentful = useCallback(
    async (params) => {
      setState({
        ...state,
        error: null,
        loading: true,
        success: false,
      });
      try {
        const space = await client.getSpace(spaceId);
        const environment = await space.getEnvironment(environmentId);
        let response;

        const handlers = {
          [REMOVE_PREVIEW_URL]: removePreviewUrlHandler,
          [UPDATE_COMMUNITY]: updateCommunityHandler,
          [CREATE_ADD_ON]: createAddOnHandler,
          [CREATE_RECOMMENDATION]: createRecommendationHandler,
          [CREATE_USER]: createUserHandler,
          [CREATE_COMMUNITY]: createCommunityHandler,
          [CLONE_COMMUNITY]: cloneCommunityHandler,
          [UPLOAD_IMAGE]: uploadImageHandler,
          [CREATE_ROOM]: createRoomHandler,
          [CREATE_HARDWARE_LEVEL]: createHardwareLevelHandler,
          [CREATE_CABINET_LEVEL]: createCabinetLevelHandler,
          [CREATE_UNCATEGORIZED_HARDWARE_LEVEL]:
            createUncategorizedHardwareLevelHandler,
          [DELETE_ROOM]: deleteRoomHandler,
          [DELETE_RECOMMENDATION]: deleteRecommendationHandler,
          [DELETE_ADD_ON]: deleteAddOnHandler,
          [DELETE_COMMUNITY]: deleteCommunityHandler,
        };

        if (!handlers[params.type]) {
          throw new Error(`Unknown Contentful action type`);
        }

        const result = await handlers[params.type](
          response,
          params,
          environment
        );
        response = result.response;

        if (isMounted.current) {
          setState({
            ...state,
            loading: false,
            success: true,
            data: response,
            error: null,
          });
        }
      } catch (error) {
        if (isMounted.current) {
          setState({
            ...state,
            loading: false,
            success: false,
            error: error,
          });
        }
      }
    },
    [client, state, isMounted]
  );

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });

  useEffect(() => {
    return () => {
      setState({
        loading: false,
        error: null,
        success: false,
        data: null,
      });
    };
  }, []);

  return [dispatchContentful, state, resetState];
}
