export const createRecommendationHandler = async (
  response,
  params,
  environment
) => {
  const { newRecoCommunityId } = params.payload;

  // Create the recommendation entry
  let newEntry = await environment.createEntry('recommendedProduct', {
    fields: {},
  });

  // Publish the recommendation entry
  let entryResponse = await environment.getEntry(newEntry.sys.id);
  await entryResponse.publish();

  // Get the community entry
  const newRecoCommunityEntry = await environment.getEntry(newRecoCommunityId);

  // Update the community entry with the new recommendation
  const currentRecommendations =
    newRecoCommunityEntry.fields?.recommendations?.['en-US'] ?? [];

  newRecoCommunityEntry.fields.recommendations = {
    'en-US': [
      ...currentRecommendations,
      {
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: entryResponse.sys.id,
        },
      },
    ],
  };

  await newRecoCommunityEntry.update().then((entry) => entry.publish());

  response = await environment.getEntry(newEntry.sys.id);

  return {
    response,
  };
};
