import { ArrowRightIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContentful } from '../../hooks/useContentful';
import ButtonPrimary from '../buttons/ButtonPrimary';
import ButtonSecondary from '../buttons/ButtonSecondary';
import { useOutletContext } from 'react-router-dom';
import { UPDATE_COMMUNITY } from '../../constants/contentfulActions';
import LoadingSpinner from '../LoadingSpinner';
import netlifyIdentity from 'netlify-identity-widget';
import Speedbump from '../modals/Speedbump';

function GeneratorNavigation({
  nextText,
  nextRoute,
  prevRoute,
  validateFields,
  dataLoaded = true,
}) {
  const { communityData, draftSaveCount, isCommunityLoaded } =
    useOutletContext();
  const navigate = useNavigate();
  const [confirmNavigateBackOpen, setConfirmNavigateBackOpen] = useState(false);
  const initialCommunityData = useMemo(() => {
    /**
     * On the GeneratorOverview component, the communityData object is updated
     * from a query to get builder data. Because of this, we have to add this check
     * to make sure the communityData object is in its final form before setting
     * the initialCommunityData object. Otherwise, a change will be detected that
     * will cause the data in Contentful to get updated unnecessarily. We add the
     * dataLoaded flag to the dependency array to ensure that the initialCommunityData
     * object is only updated when the data is fully loaded.
     *
     * On other components like GeneratorCabinets, we need to ensure the community is
     * fully loaded since rooms, cabinet levels, etc are broken out in to separate
     * paginated queries.
     */

    if (!dataLoaded) return null;
    return JSON.parse(JSON.stringify(communityData));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded, draftSaveCount]);

  const [dispatchUpdateCommunity, updateCommunityState] = useContentful();

  const validateSaveAndProceed = () => {
    netlifyIdentity.refresh();

    if (validateFields() && isCommunityLoaded) {
      if (
        JSON.stringify(initialCommunityData) !== JSON.stringify(communityData)
      ) {
        dispatchUpdateCommunity({
          type: UPDATE_COMMUNITY,
          payload: {
            communityData,
          },
        });
      } else {
        navigate(nextRoute);
      }
    } else {
      console.info(
        'Some fields are invalid or the community has not finished loading'
      );
    }
  };

  useEffect(() => {
    if (updateCommunityState.success) {
      navigate(nextRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCommunityState.success]);

  const handleNavigateBack = () => {
    if (
      JSON.stringify(initialCommunityData) !== JSON.stringify(communityData)
    ) {
      setConfirmNavigateBackOpen(true);
    } else {
      navigate(prevRoute);
    }
  };

  return (
    <>
      <Speedbump
        open={confirmNavigateBackOpen}
        setOpen={setConfirmNavigateBackOpen}
        onClose={() => {
          setConfirmNavigateBackOpen(false);
        }}
        confirmTitle={`Unsaved changes`}
        confirmBody={`You have unsaved changes. Are you sure you want to leave this page?`}
        confirmBtnText="Leave"
        warning={false}
        onConfirm={() => {
          navigate(prevRoute);
        }}
      />
      {updateCommunityState.loading && (
        <div className="fixed inset-0 bg-black opacity-50 z-10 pt-32">
          <LoadingSpinner dark />
        </div>
      )}
      <div className="fixed flex justify-center bottom-0 left-0 right-0 bg-white py-5 px-8 border-t">
        <div className=" flex w-full max-w-[1472px] justify-between">
          {prevRoute ? (
            <ButtonSecondary onClick={handleNavigateBack}>
              Previous
            </ButtonSecondary>
          ) : (
            <div></div>
          )}

          <ButtonPrimary onClick={() => validateSaveAndProceed()}>
            {nextText}
            <ArrowRightIcon className="ml-4 h-5 w-5" />
          </ButtonPrimary>
        </div>
      </div>
    </>
  );
}

export default GeneratorNavigation;
