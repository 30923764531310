import React from 'react';
import H2 from '../typography/H2';

function GeneratorSubheader({ title }) {
  return (
    <div className="my-10">
      <H2>{title}</H2>
    </div>
  );
}

export default GeneratorSubheader;
