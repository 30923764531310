import { Draggable } from '@hello-pangea/dnd';

function LevelSelectorDraggable({
  multiSelect,
  toggleSelection,
  setSelectedIds,
  selectedIds,
  id,
  index,
  selected,
  itemData,
}) {
  // @hello-pangea/dnd requires some customization to support multi-drag
  // original documentation was from react-beautiful-dnd, but the library was deprecated
  // This implementation follows the example at https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/patterns/multi-drag.md

  const keyCodes = {
    enter: 13,
    escape: 27,
    arrowDown: 40,
    arrowUp: 38,
    tab: 9,
  };

  const primaryButton = 0;

  const onKeyDown = (event, provided, snapshot) => {
    if (event.defaultPrevented) {
      return;
    }
    if (snapshot.isDragging) {
      return;
    }
    if (event.keyCode !== keyCodes.enter) {
      return;
    }
    event.preventDefault();
    performAction(event);
  };

  const onClick = (event) => {
    if (event.defaultPrevented) {
      return;
    }
    if (event.button !== primaryButton) {
      return;
    }
    event.preventDefault();
    performAction(event);
  };

  const onTouchEnd = (event) => {
    if (event.defaultPrevented) {
      return;
    }
    event.preventDefault();
    toggleSelection(id);
  };

  const wasToggleInSelectionGroupKeyUsed = (event) => {
    const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
    return isUsingWindows ? event.ctrlKey : event.metaKey;
  };

  const wasMultiSelectKeyUsed = (event) => {
    return event.shiftKey;
  };

  const performAction = (event) => {
    if (wasToggleInSelectionGroupKeyUsed(event)) {
      toggleSelection(id);
      return;
    }
    if (wasMultiSelectKeyUsed(event)) {
      let updatedSelectionArray = multiSelect(id);
      if (updatedSelectionArray === null) {
        return;
      }
      setSelectedIds(updatedSelectionArray);
      return;
    }
    toggleSelection(id);
  };

  const getItemData = (allItemData, id) => {
    let individualItemData = allItemData?.filter((itemData) => {
      return itemData.sys.id === id;
    });
    return individualItemData ? individualItemData[0] : null;
  };

  const { productCode, wood, finish, productLine, image } =
    getItemData(itemData, id) ?? {};

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => {
        return (
          <div
            className={`w-11/12 my-1 p-2 border-2 shadow-md m-auto items-center relative rounded ${productLine?.type === 'Hardware' ? '' : ''} flex ${
              selected || snapshot.isDragging
                ? 'border-aw-blue-400 bg-aw-gray-200'
                : 'border-aw-gray-300 bg-white'
            }`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={onClick}
            onTouchEnd={onTouchEnd}
            onKeyDown={(event) => {
              onKeyDown(event, provided, snapshot);
            }}
          >
            <div className="w-1/2 select-none">
              <p className="text-sm text-aw-blue-900 font-aw-sans font-bold">
                {productCode}
              </p>
              <p className="text-sm text-aw-blue-900 font-aw-sans">
                {productLine?.name}
              </p>
              <p className="text-sm text-aw-blue-900 font-aw-sans">
                {wood} {finish}
              </p>
            </div>
            {snapshot.isDragging && selectedIds && selectedIds.length > 1 && (
              <div className="ml-2 text-aw-gray-700 text-sm bg-aw-blue-400  h-8 w-8 flex justify-center items-center text-white absolute -top-4 rounded-full font-aw-sans -right-4">
                {selectedIds.length}
              </div>
            )}

            <img
              className={
                productLine?.type === 'Hardware'
                  ? `w-1/2  ${productLine?.name.includes("Knob")? "h-[80px] object-contain": "h-[60px] object-none"}`
                  : 'object-none min-w-[115px] h-[80px] min-h-[80px] object-left-bottom'
              }
              alt=""
              src={`${image?.url}${
                productLine?.type === 'Hardware'
                  ? `?w=120&q=90&fit=scale&f=bottom&fm=jpg&q=100`
                  : `?w=200&q=90&&f=bottom_left&fit=scale&fm=jpg&q=85`
              }`}
            />
          </div>
        );
      }}
    </Draggable>
  );
}

export default LevelSelectorDraggable;
