import { useState, useRef } from 'react';
import { useQuery, gql } from '@apollo/client';
import { CABINET_LEVELS_QUERY_LIMIT, PRODUCTS_COLLECTION_LIMIT } from '../helpers';

export function useCommunityCabinetLevelsQuery(id, onUpdate) {
  const [formattedData, setFormattedData] = useState({});
  const perPage = useRef(CABINET_LEVELS_QUERY_LIMIT);
  const lastLoadSkip = useRef(null);
  const retries = useRef(0);
  const maxRetries = useRef(3);

  const formatData = (data) => {
    const { community } = data;

    const { cabinetLevelsCollection } = community;

    let cabinetLevelsObj = {};
    cabinetLevelsCollection?.items?.forEach((cabinetLevel) => {
      cabinetLevelsObj[cabinetLevel.sys.id] = {
        title: cabinetLevel?.title || '',
        description: cabinetLevel?.description || '',
        ids: cabinetLevel.productsCollection.items.map((product) => {
          return product.sys.id;
        }),
      };
    });
    return {
      cabinetLevels: Object.keys(cabinetLevelsObj).length
        ? cabinetLevelsObj
        : {},
    };
  };

  const GET_COMMUNITY_CABINET_LEVELS = gql`
    query GetSingleCommunityCabinetLevels(
      $id: String!
      $skip: Int
    ) {
      community(id: $id) {
        cabinetLevelsCollection(limit: ${perPage.current}, skip: $skip) {
          total
          items {
            title
            description
            productsCollection(limit: ${PRODUCTS_COLLECTION_LIMIT}) {
              items {
                sys {
                  id
                }
              }
            }
            sys {
              id
            }
          }
        }
      }
    }
  `;

  const { loading, error, data, fetchMore } = useQuery(
    GET_COMMUNITY_CABINET_LEVELS,
    {
      variables: { id, skip: 0 },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      onCompleted,
    }
  );


  function onCompleted(rawData) {
    const formattedData = formatData(rawData);
    setFormattedData(formattedData);
    if(onUpdate) {
      onUpdate(formattedData, rawData);
    }
    requestAdditionalPages(rawData);
  }

  function requestAdditionalPages(rawData) {
    const loaded = rawData?.community?.cabinetLevelsCollection?.items?.length;
    const total = rawData?.community?.cabinetLevelsCollection?.total;

    const isRetry = lastLoadSkip.current === loaded;
    if(!isRetry) {
      retries.current = 0;
    }
    
    const hitRetryLimit = retries.current >= maxRetries.current
    if(hitRetryLimit || (loaded >= total)) {
      return;
    }

    lastLoadSkip.current = loaded;
    retries.current += 1;
    fetchMore({
      variables: {
        skip: loaded,
      },
    });
  }

  return { data, formattedData, loading, error };
}
