import React, { Component } from 'react';

/**
 * Handles errors and logs them to the console.
 * @param {string} msg - The error message.
 * @param {Error} error - The error object.
 * @param {Object} errorInfo - Additional error information.
 */
const errorHandler = (msg, error, errorInfo) => {
  // extend with Sentry or another error tracking service here
  console.error(msg, error, errorInfo);
};

/**
 * Error boundary component that catches and handles errors in its child components.
 */
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  /**
   * Static method that updates the component state when an error is caught.
   * @param {Error} error - The caught error.
   * @returns {Object} - The updated state object.
   */
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  /**
   * Method that handles the caught error and calls the provided `onError` prop if available.
   * @param {Error} error - The caught error.
   * @param {Object} errorInfo - Additional information about the error.
   */
  componentDidCatch(error, errorInfo) {
    const msg = `[ErrorBoundary]: ${error.message}`;
    errorHandler(msg, error, errorInfo);
    if (this.props.onError) {
      this.props.onError(error, errorInfo);
    }
  }

  /**
   * Resets the error state to `false`.
   */
  resetError = () => this.setState({ hasError: false });

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <React.Fragment>
          {this.props.fallbackComponent ? (
            React.cloneElement(this.props.fallbackComponent, {
              resetError: this.resetError,
            })
          ) : (
            <h1>Something went wrong.</h1>
          )}
        </React.Fragment>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
