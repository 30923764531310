import * as React from 'react';

const SquarePlusIcon = (props) => (
  <svg
    width="1em"
    height="1em"

    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Zm0 16H5V5h14v14Zm-6-2h-2v-4H7v-2h4V7h2v4h4v2h-4v4Z"

      fillOpacity={0.54}
    />
  </svg>
);

export default SquarePlusIcon;
