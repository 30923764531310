import { A, P } from '../components/typography';
import ButtonPrimary from '../components/buttons/ButtonPrimary.jsx';
import authLogo from '../assets/images/auth-logo.svg';
import netlifyIdentity from 'netlify-identity-widget';
import { useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';

function Login() {
  const navigate = useNavigate();
  let outletContext = useOutletContext();

  useEffect(() => {
    if (outletContext.currentUser) {
      navigate('/');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="fixed left-0 right-0 top-0 bottom-0 bg-[url('assets/images/auth-bg.jpg')] bg-cover bg-no-repeat">
      <div className="mx-auto mt-52 w-3/4 max-w-xl rounded-xl bg-gray-100 pt-16 pb-8 md:mt-60 lg:mt-80">
        <div className="px-10">
          <img
            className="mx-auto mb-16 w-96"
            src={authLogo}
            alt="Timberlake cabinetry logo"
          />
          <ButtonPrimary
            className="mb-5 inline-block w-full justify-center"
            onClick={() => {
              netlifyIdentity.open();
            }}
          >
            Sign in
          </ButtonPrimary>

          <div>
            <P className="text-center">
              Need to sign up?{' '}
              <A href="mailto:?subject=Requesting Netlify Login for Timberlake Microsite Tool">
                Request a Netlify login
              </A>{' '}
              from the Single Family Marketing Team.
            </P>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
