import React from 'react';

function ButtonTertiary({ children, onClick, className, disabled=false }) {
  return (
    <button
      className={`flex h-[55px] items-center rounded bg-aw-blue-400 disabled:bg-gray-300 py-2 px-3 font-aw-serif font-bold shadow-md text-white hover:opacity-90 lg:py-4 lg:px-6 ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default ButtonTertiary;
