import axios from 'axios';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  NETLIFY_DELETE,
  NETLIFY_PREVIEW,
  NETLIFY_PUBLISH,
  NETLIFY_PUBLISH_RENAMED,
  NETLIFY_UNPUBLISH,
} from '../constants/netlifyActions';

/**
 * Custom React hook to deploy, publish, unpublish, delete and rename microsites on Netlify.
 * @param {function} onCompleted - Callback function to be called when the deployment is completed.
 * @returns {{deployStatus: object, deploy: function}} - An object containing the deployment status and a function to deploy.
 */
const useNetlifyActions = (onCompleted = null) => {
  const [status, setStatus] = useState({
    loading: false,
    success: false,
    error: false,
  });

  const { currentUser } = useOutletContext();

  // Get the base URL for the Netlify functions
  const baseUrl = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? ':' + window.location.port : ''
  }/.netlify/functions`;

  // Get the bearer token to authenticate requests
  const bearerToken = `Bearer ${currentUser.token.access_token}`;

  // Set the headers for the axios requests
  const headers = {
    'Content-Type': 'application/json',
    Authorization: bearerToken,
  };

  /**
   * Makes an axios request to a Netlify function and returns the response.
   * @param {string} url - The URL of the Netlify function to make the request to.
   * @param {object} data - The data to be sent with the request.
   * @returns {Promise} - The axios response or null if there's an error.
   */
  const makeRequest = async (url, data) => {
    try {
      const response = await axios.post(url, data, { headers });
      return response;
    } catch (error) {
      console.error(`Error: ${url}`, error);
      setStatus({ loading: false, success: false, error: true });
      return null; // Return null when there's an error to let checkBuildStatus know that something went wrong
    }
  };

  /**
   * Polls the build status of a Netlify deployment until it's either ready or has an error.
   * @param {string} communityId - The ID of the community/microsite to deploy.
   * @param {string} buildType - The type of build to check (preview or publish).
   * @param {function} onCompleted - Callback function to be called when the deployment is completed.
   * @param {boolean} executeCallback - Whether or not to execute the onCompleted callback function.
   * @returns {Promise} - A Promise that resolves when the build status is ready or has an error.
   */
  const checkBuildStatus = (
    communityId,
    buildType,
    onCompleted,
    executeCallback = true
  ) => {
    return new Promise(async (resolve) => {
      const isPreview = buildType === 'preview';
      const checkInterval = setInterval(async () => {
        try {
          const buildStatusResponse = await makeRequest(
            `${baseUrl}/build-status`,
            {
              branch: isPreview ? `site/${communityId}` : `main`,
            }
          );

          // if buildStatusResponse is null, it means something went awry and we need to get out of here
          // otherwise it will continue to check status for five minutes
          if (buildStatusResponse === null) {
            clearInterval(checkInterval);
            resolve();
            return;
          }

          if (buildStatusResponse?.data.status === 'error') {
            clearInterval(checkInterval);
            setStatus({ loading: false, success: false, error: true });

            if (onCompleted) {
              onCompleted();
            }

            resolve();
            return;
          }

          if (buildStatusResponse?.data.status === 'ready') {
            clearInterval(checkInterval);

            if (executeCallback) {
              setStatus({ loading: false, success: true, error: false });

              if (onCompleted) {
                onCompleted();
              }
            }

            resolve();
          }
        } catch (error) {
          clearInterval(checkInterval);
          resolve();
        }
      }, 10000);

      // Stop checking for build status after 5 minutes
      setTimeout(() => {
        clearInterval(checkInterval);
        resolve();
      }, 5 * 60 * 1000);
    });
  };

  /**
    Deploys, publishes, unpublishes, deletes, or renames a microsite on Netlify.
    @param {object} data - An object containing the type of deployment, the ID of the community/microsite to deploy, and other optional data.
    @returns {Promise} - A Promise that resolves when the deployment is completed.
  */
  const deploy = async (data) => {
    setStatus({ loading: true, success: false, error: false });
    const { type, communityId } = data;

    switch (type) {
      case NETLIFY_UNPUBLISH:
        try {
          // Set the microsite to unpublished on Netlify by passing `publish: false`
          await makeRequest(`${baseUrl}/publish-microsite`, {
            communityId,
            publish: false,
          });

          // Check the build status of the microsite
          await checkBuildStatus(communityId, 'publish', onCompleted);
        } catch (error) {
          setStatus({ loading: false, success: false, error: true });
        }

        break;

      case NETLIFY_DELETE:
        // Set the microsite to unpublished on Netlify
        await makeRequest(`${baseUrl}/publish-microsite`, {
          communityId,
          publish: false,
        });

        // Delete the microsite from Netlify
        await makeRequest(`${baseUrl}/delete-microsite`, { communityId });

        setStatus({ loading: false, success: true, error: false });

        if (onCompleted) {
          onCompleted();
        }

        break;

      case NETLIFY_PUBLISH_RENAMED:
        // Add a redirect for the old community name to the new one
        await makeRequest(`${baseUrl}/add-redirect`, {
          communityId,
          oldCommunityName: data.oldCommunityName,
        });

        // Deploy the microsite with the new community name
        const renameDeployResponse = await makeRequest(
          `${baseUrl}/deploy-microsite`,
          {
            communityId,
            branchName: `site/${communityId}`,
            previewDeploy: false,
          }
        );

        if (renameDeployResponse?.status === 202) {
          // Check the build status of the deploy
          // First call, set executeCallback to false
          // so that the onCompleted callback isn't called just yet
          await checkBuildStatus(communityId, 'preview', onCompleted, false);

          // Publish the microsite
          const renamePublishResponse = await makeRequest(
            `${baseUrl}/publish-microsite`,
            {
              communityId,
              publish: true,
            }
          );

          if (renamePublishResponse?.status === 202) {
            // Check the build status of the publish
            // this time passing true to execute the onCompleted callback
            await checkBuildStatus(communityId, 'publish', onCompleted, true);
          }
        }

        break;

      case NETLIFY_PUBLISH:
        // Deploy the microsite
        const deployResponse = await makeRequest(
          `${baseUrl}/deploy-microsite`,
          {
            communityId,
            branchName: `site/${communityId}`,
            previewDeploy: false,
          }
        );
        if (deployResponse?.status === 202) {
          // Check the build status of the deploy
          // First call, set executeCallback to false
          // so that the onCompleted callback isn't called just yet
          await checkBuildStatus(communityId, 'preview', onCompleted, false);

          // Publish the microsite
          const publishResponse = await makeRequest(
            `${baseUrl}/publish-microsite`,
            { communityId, publish: true }
          );

          if (publishResponse?.status === 202) {
            // Check the build status of the publish
            // this time passing true to execute the onCompleted callback
            await checkBuildStatus(communityId, 'publish', onCompleted, true);
          }
        }

        break;

      case NETLIFY_PREVIEW:
        // Deploy the microsite as a preview
        const previewResponse = await makeRequest(
          `${baseUrl}/deploy-microsite`,
          {
            communityId,
            branchName: `site/${communityId}`,
            previewDeploy: true,
          }
        );

        if (previewResponse?.status === 202) {
          // Check the build status of the preview deploy
          await checkBuildStatus(communityId, 'preview', onCompleted, true);
        }

        break;

      default:
        break;
    }
  };

  return { deployStatus: status, deploy };
};

export default useNetlifyActions;
