export const removePreviewUrlHandler = async (
  response,
  params,
  environment
) => {
  response = await environment.getEntry(params.payload.communityId);
  response.fields = {
    ...response.fields,
    previewUrl: { 'en-US': null },
  };
  try {
    await response.update();
    await response.publish();
  } catch (error) {
    console.error(error);
  }

  return {
    response,
  };
};

export const createUserHandler = async (response, params, environment) => {
  const { email } = params.payload;
  let newUserEntry = null;

  try {
    // create user entry
    newUserEntry = await environment.createEntry('user', {
      fields: {
        email: {
          'en-US': email,
        },
      },
    });
    await newUserEntry.publish();
    response = await environment.getEntry(newUserEntry.sys.id);
  } catch (error) {
    console.error(error);
  }
  return {
    response,
  };
};

export const uploadImageHandler = async (response, params, environment) => {
  const { file, imageTags } = params.payload;
  const asset = await environment.createAssetFromFiles({
    fields: {
      file: {
        'en-US': {
          contentType: file.contentType,
          fileName: file.fileName,
          file: file.file,
          title: file.name,
        },
      },
    },
  });
  await asset.processForAllLocales();

  const assetId = asset.sys.id;
  await environment
    .getAsset(assetId)
    .then((asset) => {
      asset.metadata = {
        tags: imageTags.map((tag) => {
          return {
            sys: {
              type: 'Link',
              linkType: 'Tag',
              id: tag,
            },
          };
        }),
      };
      return asset.update();
    })
    .then((asset) => asset.publish());
  response = await environment.getAsset(assetId);
  return {
    response,
  };
};

export const createRoomHandler = async (response, params, environment) => {
  const { communityId, roomName } = params.payload;
  const room = await environment

    .createEntry('room', {
      fields: {
        name: {
          'en-US': roomName,
        },
      },
    })
    .then((entry) => entry.publish());

  const community = await environment.getEntry(communityId);
  community.fields.rooms = {
    'en-US': [
      ...(community.fields?.rooms?.['en-US'] ?? []),
      {
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: room.sys.id,
        },
      },
    ],
  };
  await community
    .update()
    .then((updatedCommunity) => updatedCommunity.publish());
  // return the room entry in the useQuery data
  response = await environment.getEntry(room.sys.id);
  return {
    response,
  };
};

export const createHardwareLevelHandler = async (
  response,
  params,
  environment
) => {
  const { communityId2, levelTitle } = params.payload;
  const level = await environment
    .createEntry('level', {
      fields: {
        title: {
          'en-US': levelTitle,
        },
      },
    })
    .then((entry) => entry.publish());

  const communityEntry = await environment.getEntry(communityId2);
  communityEntry.fields.hardwareLevels = {
    'en-US': [
      ...(communityEntry.fields?.hardwareLevels?.['en-US'] ?? []),
      {
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: level.sys.id,
        },
      },
    ],
  };
  await communityEntry
    .update()
    .then((updatedCommunity) => updatedCommunity.publish());
  // return the level entry in the useQuery data
  response = await environment.getEntry(level.sys.id);
  return {
    response,
  };
};

export const createUncategorizedHardwareLevelHandler = async (
  response,
  params,
  environment
) => {
  const { communityId } = params.payload;
  const level = await environment
    .createEntry('level', {
      fields: {
        title: {
          'en-US': 'Uncategorized',
        },
      },
    })
    .then((entry) => entry.publish());

  const communityEntry = await environment.getEntry(communityId);
  communityEntry.fields.hardwareLevels = {
    'en-US': [
      {
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: level.sys.id,
        },
      },
    ],
  };
  await communityEntry
    .update()
    .then((updatedCommunity) => updatedCommunity.publish());
  // return the level entry in the useQuery data
  response = await environment.getEntry(level.sys.id);
  return {
    response,
  };
};

export const createUncategorizedCabinetLevelHandler = async (
  response,
  params,
  environment
) => {
  const { communityId } = params.payload;
  const level = await environment
    .createEntry('level', {
      fields: {
        title: {
          'en-US': 'Uncategorized',
        },
      },
    })
    .then((entry) => entry.publish());

  const communityEntry = await environment.getEntry(communityId);
  communityEntry.fields.cabinetLevels = {
    'en-US': [
      {
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: level.sys.id,
        },
      },
    ],
  };
  await communityEntry
    .update()
    .then((updatedCommunity) => updatedCommunity.publish());
  // return the level entry in the useQuery data
  response = await environment.getEntry(level.sys.id);
  return {
    response,
  };
};

export const createCabinetLevelHandler = async (
  response,
  params,
  environment
) => {
  const { communityId3, levelTitle2 } = params.payload;
  const level2 = await environment
    .createEntry('level', {
      fields: {
        title: {
          'en-US': levelTitle2,
        },
      },
    })
    .then((entry) => entry.publish());

  const communityEntry2 = await environment.getEntry(communityId3);
  communityEntry2.fields.cabinetLevels = {
    'en-US': [
      ...(communityEntry2.fields?.cabinetLevels?.['en-US'] ?? []),
      {
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: level2.sys.id,
        },
      },
    ],
  };
  await communityEntry2
    .update()
    .then((updatedCommunity) => updatedCommunity.publish());
  // return the level entry in the useQuery data
  response = await environment.getEntry(level2.sys.id);
  return {
    response,
  };
};

export const deleteRoomHandler = async (response, params, environment) => {
  // find the link to this room on the current community and delete it
  // then delete the room entry
  const { deleteRoomId, deleteRoomCommunityId } = params.payload;
  const communityToDeleteRoom = await environment.getEntry(
    deleteRoomCommunityId
  );
  communityToDeleteRoom.fields.rooms = {
    'en-US': communityToDeleteRoom.fields.rooms['en-US'].filter(
      (room) => room.sys.id !== deleteRoomId
    ),
  };
  await communityToDeleteRoom
    .update()
    .then((updatedCommunity) => updatedCommunity.publish());
  const roomToDelete = await environment.getEntry(deleteRoomId);
  await roomToDelete
    .unpublish()
    .then((unpublishedEntry) => unpublishedEntry.delete());
  response = await environment.getEntry(deleteRoomCommunityId);
  return {
    response,
  };
};

export const deleteRecommendationHandler = async (
  response,
  params,
  environment
) => {
  // find the link to this recommendation on the current room and delete it
  // then delete the recommendation entry
  const { deleteRecommendationId, deleteRecommendationCommunityId } =
    params.payload;
  const communityToDeleteRecommendation = await environment.getEntry(
    deleteRecommendationCommunityId
  );
  communityToDeleteRecommendation.fields.recommendations = {
    'en-US': communityToDeleteRecommendation.fields.recommendations[
      'en-US'
    ].filter(
      (recommendation) => recommendation.sys.id !== deleteRecommendationId
    ),
  };
  await communityToDeleteRecommendation
    .update()
    .then((updatedCommunity) => updatedCommunity.publish());
  const recommendationToDelete = await environment.getEntry(
    deleteRecommendationId
  );
  await recommendationToDelete
    .unpublish()
    .then((unpublishedEntry) => unpublishedEntry.delete());
  response = await environment.getEntry(deleteRecommendationCommunityId);
  return {
    response,
  };
};

export const deleteAddOnHandler = async (response, params, environment) => {
  // find the link to this add-on on the current room and delete it
  // then delete the add-on entry
  const { deleteAddOnId, deleteAddOnCommunityId } = params.payload;
  const communityToDeleteAddOn = await environment.getEntry(
    deleteAddOnCommunityId
  );
  console.log(communityToDeleteAddOn);
  communityToDeleteAddOn.fields.addons = {
    'en-US': communityToDeleteAddOn.fields.addons['en-US'].filter(
      (addOn) => addOn.sys.id !== deleteAddOnId
    ),
  };
  await communityToDeleteAddOn
    .update()
    .then((updatedCommunity) => updatedCommunity.publish());
  const addOnToDelete = await environment.getEntry(deleteAddOnId);
  await addOnToDelete
    .unpublish()
    .then((unpublishedEntry) => unpublishedEntry.delete());
  response = await environment.getEntry(deleteAddOnCommunityId);
  return {
    response,
  };
};

export const deleteCommunityHandler = async (response, params, environment) => {
  const { deleteCommunityId, deleteCommunityUserId } = params.payload;
  const communityToDelete = await environment.getEntry(deleteCommunityId);
  const communityToDeleteUser = await environment.getEntry(
    deleteCommunityUserId
  );

  // delete the link to the community from the user
  communityToDeleteUser.fields.communities = {
    'en-US': communityToDeleteUser.fields.communities['en-US'].filter(
      (community) => community.sys.id !== deleteCommunityId
    ),
  };

  await communityToDelete
    .unpublish()
    .then((unpublishedEntry) => unpublishedEntry.archive());
  await communityToDeleteUser
    .update()
    .then((updatedUser) => updatedUser.publish());
  response = await environment.getEntry(deleteCommunityUserId);
  return {
    response,
  };
};
