import * as React from 'react';

const EditIcon = ({className}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    strokeWidth="0"
    stroke="currentColor"
    className={`w-6 ${className}`}
  >
    <path d="M15.042 9.5a.791.791 0 0 0-.791.791v6.334a.793.793 0 0 1-.792.791H2.375a.792.792 0 0 1-.792-.791V5.54c0-.436.355-.792.792-.792H8.71a.791.791 0 1 0 0-1.583H2.375A2.378 2.378 0 0 0 0 5.541v11.084A2.378 2.378 0 0 0 2.375 19H13.46a2.378 2.378 0 0 0 2.375-2.375V10.29a.791.791 0 0 0-.792-.791Z" />
    <path d="M7.423 8.779a.4.4 0 0 0-.108.201l-.56 2.8a.397.397 0 0 0 .466.465l2.799-.56a.394.394 0 0 0 .203-.108l6.263-6.264-2.798-2.798-6.265 6.264ZM18.42.579a1.98 1.98 0 0 0-2.798 0l-1.096 1.096 2.799 2.798 1.096-1.096c.373-.372.58-.87.58-1.398 0-.53-.207-1.026-.58-1.4Z" />
  </svg>
);

export default EditIcon;
