export const createAddOnHandler = async (response, params, environment) => {
  const { newAddOnCommunityId } = params.payload;

  // Create the add-on entry
  response = await environment.createEntry('addon', {
    fields: {},
  });

  // Get the community entry
  const newAddOnCommunityEntry = await environment.getEntry(
    newAddOnCommunityId
  );

  // Update the community entry with the new add-on
  if (!newAddOnCommunityEntry.fields.addons) {
    newAddOnCommunityEntry.fields.addons = { 'en-US': [] };
  }
  const currentAddOns = newAddOnCommunityEntry.fields.addons['en-US'];

  currentAddOns.push({
    sys: {
      type: 'Link',
      linkType: 'Entry',
      id: response.sys.id,
    },
  });

  await newAddOnCommunityEntry.update();
  const updatedAddOnCommunityEntry = await environment.getEntry(
    newAddOnCommunityId
  );
  await updatedAddOnCommunityEntry.publish();
  return {
    response,
  };
};
