import React, { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { GeneratorAddOnCard } from '../../components/generator';
import GeneratorHeader from '../../components/generator/GeneratorHeader';
import GeneratorNavigation from '../../components/generator/GeneratorNavigation';
import SquarePlusIcon from '../../components/icons/SquarePlusIcon';
import { P } from '../../components/typography';
import { SizeConstrained } from '../../components/layout/Layout';
import { useContentful } from '../../hooks/useContentful';
import { CREATE_ADD_ON } from '../../constants/contentfulActions';
import LoadingSpinner from '../../components/LoadingSpinner';
import LoaderModal from '../../components/modals/LoaderModal';

function GeneratorAddons() {
  const { id } = useParams();
  const { communityData, setCommunityData, isCommunityLoaded } = useOutletContext();
  const [dispatchCreateNewAddOn, createNewAddOnState] = useContentful();
  const [validationErrors, setValidationErrors] = useState({});

  const handleAddNewAddOn = () => {
    dispatchCreateNewAddOn({
      type: CREATE_ADD_ON,
      payload: {
        newAddOnCommunityId: id,
      },
    });
  };

  const checkIfRichTextEmpty = (richText) => {
     const nodeIsEmpty = (node) => {
       if (node.content) {
         return node.content.every((childNode) => nodeIsEmpty(childNode));
       } else {
         return !node.value || node.value.trim().length === 0;
       }
     };

     return nodeIsEmpty(richText.json);
  }

    const validateFields = () => {
      const errors = {
        addons: {},
      };

      communityData.addOns.forEach((addon, index) => {
        // Initialize a sub-object for the current room if it doesn't already exist
        if (!errors.addons[addon.id]) {
          errors.addons[addon.id] = {};
        }

        if (addon.image.id === null) {
          errors.addons[
            addon.id
          ].image = `Please select an image`;
        }

        if (addon.title === '') {
          errors.addons[
            addon.id
          ].title = `Please enter a name`;
        }
        if (addon.description === '' || checkIfRichTextEmpty(addon.description)) {
          errors.addons[
            addon.id
          ].description = `Please enter a description`;
        }
        if (addon.videoUrl !== '' && !addon.videoUrl.includes('vimeo.com')) {
          errors.addons[
            addon.id
          ].videoUrl = `Video URL must link to a Vimeo video`;
        }
      });

      setValidationErrors(errors);

      const hasErrors = Object.values(errors.addons).some(
        (addonErrors) =>
          Object.values(addonErrors).some((error) => !!error)
      );

      return !hasErrors;
    };


  useEffect(() => {
    if (createNewAddOnState.success) {
      setCommunityData({
        ...communityData,
        addOns: [
          ...communityData.addOns,
          {
            id: createNewAddOnState.data.sys.id,
            title: '',
            description: '',
            videoUrl: '',
            image: {
              id: null,
              url: null,
            },
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNewAddOnState.success]);

  return (
    <>
      <LoaderModal show={createNewAddOnState.loading} />
      <SizeConstrained>
        <GeneratorHeader title="Optional: Add-Ons" />
        <P className="">
          If your community includes options for add-ons such as cushion close
          technology, organizational accessories, or drawer inserts, highlight
          them here. For each add on, include an image and a brief description
          that highlights its features and benefits.
        </P>
        {communityData.addOns.length ? (
          <div>
            {communityData.addOns.map((addOn, index) => {
              return (
                <GeneratorAddOnCard
                  currentAddOn={addOn}
                  key={addOn.id}
                  index={index}
                  communityId={id}
                  errors={validationErrors.addons?.[addOn.id]}
                />
              );
            })}
          </div>
        ) : (
          <></>
        )}

        <div className="mt-8 flex justify-start">
          <div
            className="flex cursor-pointer"
            onClick={() => handleAddNewAddOn()}
          >
            <span className="font-aw-sans text-aw-gray-700">
              Add {communityData.addOns.length ? 'another' : ''} add-on
            </span>
            <SquarePlusIcon className="ml-2 h-10 w-10 fill-aw-gray-700" />
          </div>
        </div>

        <GeneratorNavigation
          nextText="Review & Publish"
          nextRoute={`/generator/${id}/publish`}
          prevRoute={`/generator/${id}/recommendations`}
          validateFields={validateFields}
          dataLoaded={isCommunityLoaded}
        />
      </SizeConstrained>
    </>
  );
}

export default GeneratorAddons;
