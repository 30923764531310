import { Dialog } from '@headlessui/react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import ButtonPrimary from '../../buttons/ButtonPrimary';
import ButtonSecondary from '../../buttons/ButtonSecondary';
import { P } from '../../typography';

export const LevelDescription = ({ levelId, levelDataKey }) => {
  const { communityData, setCommunityData } = useOutletContext();
  const [editingMode, setEditingMode] = useState(false);
  const [description, setDescription] = useState(
    communityData[levelDataKey][levelId].description || ''
  );

  if (!communityData[levelDataKey]) {
    return;
  }

  const handleCancel = () => {
    setEditingMode(false);
  };

  const handleSubmit = () => {
    let communityDataCopy = communityData;
    communityDataCopy[levelDataKey][levelId].description = description;
    setCommunityData(communityDataCopy);
    setEditingMode(false);
  };

  return (
    <>
      <Dialog
        open={editingMode}
        onClose={() => setEditingMode(false)}
        className="relative z-50"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        {/* Full-screen scrollable container */}
        <div className="fixed inset-0 overflow-y-auto">
          {/* Container to center the panel */}
          <div className="flex min-h-full items-center justify-center p-4">
            {/* The actual dialog panel  */}
            <Dialog.Panel className="mx-auto w-96 rounded bg-white">
              <div className="w-11/12 mx-auto h-96 py-6 px-4">
                <Dialog.Title className="pb-2">
                  <P>Edit your level description</P>
                </Dialog.Title>{' '}
                <textarea
                  maxLength="500"
                  id={`description-input-${levelId}`}
                  name={`description-input-${levelId}`}
                  value={description}
                  className="rounded w-full h-[70%] max-h-[70%] min-h-[70%] resize-none mb-3"
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                ></textarea>
                <div className="flex flex-nowrap justify-center gap-4">
                  <ButtonPrimary onClick={handleSubmit}>Submit</ButtonPrimary>
                  <ButtonSecondary onClick={handleCancel}>
                    Cancel
                  </ButtonSecondary>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
      <div className="flex justify-between items-center">
        <div className="pl-2">
          <p className="w-40 block whitespace-nowrap text-ellipsis overflow-hidden font-aw-sans text-base text-aw-blue-900">
            {communityData[levelDataKey][levelId].description ? (
              communityData[levelDataKey][levelId].description
            ) : (
              <em className="text-aw-gray-400">no description</em>
            )}
          </p>
        </div>
        <div>
          <button className="w-8 h-8" onClick={() => setEditingMode(true)}>
            <PencilSquareIcon />
          </button>
        </div>
      </div>
    </>
  );
};
