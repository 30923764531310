import React, { useState } from 'react';
import FieldLabel from '../forms/FieldLabel';
import TextInput from '../forms/TextInput';
import DeleteIcon from '../icons/DeleteIcon';
import ImageUploadOrSelect from '../ImageUploadOrSelect';
import { useOutletContext } from 'react-router-dom';
import GeneratorRoomBuilder from './GeneratorRoomBuilder';
import { useContentful } from '../../hooks/useContentful';
import LoaderModal from '../modals/LoaderModal';
import { DELETE_ROOM } from '../../constants/contentfulActions';
import Speedbump from '../modals/Speedbump';
import ErrorMessage from '../ErrorMessage';

function GeneratorRoomCard({ index, currentRoom, communityId, errors }) {
  const [roomName, setRoomName] = useState(currentRoom?.name || '');
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const { communityData, setCommunityData, builderImageTag } =
    useOutletContext();
  const [dispatchDeleteRoom, deleteRoomState] = useContentful();

  const onNameChange = (e) => {
    setRoomName(e.target.value);
    const newRoomsData = [...communityData.rooms];
    newRoomsData[index].name = e.target.value;
    setCommunityData({ ...communityData, rooms: newRoomsData });
  };

  const handleDeleteRoom = async () => {
    setConfirmDeleteOpen(false);
    await dispatchDeleteRoom({
      type: DELETE_ROOM,
      payload: {
        deleteRoomCommunityId: communityId,
        deleteRoomId: currentRoom.id,
      },
    });
    const newRoomsData = [...communityData.rooms];
    newRoomsData.splice(index, 1);
    setCommunityData({ ...communityData, rooms: newRoomsData });
  };

  const handleSelectImage = (image) => {
    const newRoomsData = [...communityData.rooms];
    newRoomsData[index].image = image;
    setCommunityData({ ...communityData, rooms: newRoomsData });
  };

  const handleSelectBannerImage = (image) => {
    const newRoomsData = [...communityData.rooms];
    newRoomsData[index].headerImage = image;
    setCommunityData({ ...communityData, rooms: newRoomsData });
  };

  return (
    <>
      <Speedbump
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={handleDeleteRoom}
        onClose={() => setConfirmDeleteOpen(false)}
        confirmTitle="Delete Room"
        confirmBody="Are you sure you want to delete this room? This action cannot be undone."
        confirmBtnText="Delete"
      />
      <LoaderModal show={deleteRoomState.loading} />
      <div className="border-b bg-aw-gray-100 py-6">
        <h3 className="py-4 font-aw-serif font-bold text-lg text-aw-blue-600">Room {index + 1}</h3>
        <div className="flex w-full gap-14">
          <div className="w-full">
            <TextInput
              label="Room name:"
              value={roomName}
              onChange={onNameChange}
            />
            <ErrorMessage show={errors?.name !== null}>
              {errors?.name}
            </ErrorMessage>
          </div>
          <div className="mt-10 w-full">
            <GeneratorRoomBuilder currentRoom={currentRoom} index={index} />
            <ErrorMessage show={errors?.products !== null}>
              {errors?.products}
            </ErrorMessage>
          </div>
        </div>
        <div className="flex gap-5 mt-4">
          <div className="basis-3/12">
            <FieldLabel>
              Room image:
              <span className="block text-xs text-aw-gray-300">
                680x500 recommended
                <br />
                (or 1180x870 for single-room communities)
              </span>
            </FieldLabel>
            {/* if the user has entered a name for the new room, display it in the image upload component, otherwise name it numerically */}
            <ImageUploadOrSelect
              setImage={handleSelectImage}
              imageType={roomName ? roomName : `Room ${index + 1}`}
              currentImage={currentRoom.image}
              targetEntryId={currentRoom.id}
              imageTagToQuery="roomHighlight"
              tagsToAddToUploads={[builderImageTag, 'roomHighlight']}
              aspect="portrait"
            />
            <ErrorMessage show={errors?.image !== null}>
              {errors?.image}
            </ErrorMessage>
          </div>
          <div className="basis-9/12">
            <div className="">
              <FieldLabel>
                Banner image:{' '}
                <span className="block text-xs text-aw-gray-300">
                  3080x840 recommended
                </span>
              </FieldLabel>
              <ImageUploadOrSelect
                setImage={handleSelectBannerImage}
                imageType={roomName ? roomName : `Room ${index + 1}`}
                currentImage={currentRoom.headerImage}
                targetEntryId={currentRoom.id}
                tagsToAddToUploads={[builderImageTag, 'hero']}
                imageTagToQuery="hero"
              />
              <ErrorMessage show={errors?.headerImage !== null}>
                {errors?.headerImage}
              </ErrorMessage>
            </div>
          </div>
        </div>
        {communityData.rooms.length > 1 ? (
          <div
            className="mt-4 flex w-min cursor-pointer rounded-lg border py-2 px-4 text-aw-gray-700 hover:fill-red-400 hover:text-red-400 hover:border-red-400"
            onClick={() => setConfirmDeleteOpen(true)}
          >
            <DeleteIcon className="mr-3 fill-current" />
            <span className="font-aw-sans">Delete</span>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default GeneratorRoomCard;
