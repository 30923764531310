import React, { useState } from 'react';
import FieldLabel from '../forms/FieldLabel';
import TextInput from '../forms/TextInput';
import ImageUploadOrSelect from '../ImageUploadOrSelect';
import DeleteIcon from '../icons/DeleteIcon';
import { useOutletContext } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { htmlToContentfulRichText } from '../../graphql/helpers';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { useContentful } from '../../hooks/useContentful';
import { DELETE_ADD_ON } from '../../constants/contentfulActions';
import Speedbump from '../modals/Speedbump';
import LoaderModal from '../modals/LoaderModal';
import ErrorMessage from '../ErrorMessage';

function GeneratorAddOnCard({ currentAddOn, index, communityId, errors }) {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [addOnName, setAddOnName] = useState(currentAddOn.title);
  const [addOnVideoUrl, setAddOnVideoUrl] = useState(currentAddOn.videoUrl);
  const [description, setDescription] = useState(
    documentToHtmlString(currentAddOn.description.json)
  );
  const { communityData, setCommunityData, builderImageTag } =
    useOutletContext();
  const [dispatchDeleteAddOn, deleteAddOnState] = useContentful();

  const onNameChange = (e) => {
    setAddOnName(e.target.value);
    const newAddOnsData = [...communityData.addOns];
    newAddOnsData[index].title = e.target.value;
    setCommunityData({ ...communityData, addOns: newAddOnsData });
  };

  const onVideoUrlChange = (e) => {
    setAddOnVideoUrl(e.target.value);
    const newAddOnsData = [...communityData.addOns];
    newAddOnsData[index].videoUrl = e.target.value;
    setCommunityData({ ...communityData, addOns: newAddOnsData });
  };


  function onDescriptionChange(content, delta, source) {
    if (source === 'user') {
      const newAddOnsData = [...communityData.addOns];
      newAddOnsData[index].description = {
        json: htmlToContentfulRichText(content),
      };
      setCommunityData({ ...communityData, addOns: newAddOnsData });
    }
    setDescription(content);
  }

  const handleSelectImage = (image) => {
    const newAddOnsData = [...communityData.addOns];
    newAddOnsData[index].image = image;
    setCommunityData({ ...communityData, addOns: newAddOnsData });
  };

  const handleDeleteAddOn = async () => {
    setConfirmDeleteOpen(false);
    await dispatchDeleteAddOn({
      type: DELETE_ADD_ON,
      payload: {
        deleteAddOnCommunityId: communityId,
        deleteAddOnId: currentAddOn.id,
      },
    });
    const newAddOnsData = [...communityData.addOns];
    newAddOnsData.splice(index, 1);
    setCommunityData({ ...communityData, addOns: newAddOnsData });
  };

  return (
    <>
      <Speedbump
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={handleDeleteAddOn}
        confirmTitle="Delete Add-on"
        confirmBody="Are you sure you want to delete this add-on? This action cannot be undone."
        confirmBtnText="Delete"
      />
      <LoaderModal show={deleteAddOnState.loading} />
      <div className="mt-10 border-b pb-8">
        <div className=" flex">
          <div className="w-full">
            <TextInput
              label="Add-on name:"
              id={`name-${currentAddOn.id}`}
              onChange={onNameChange}
              value={addOnName}
            />
            <ErrorMessage show={errors?.title !== null}>
              {errors?.title}
            </ErrorMessage>
            <div className="mt-4 relative">
              <TextInput
                label="Vimeo URL:"
                id={`vimeo-${currentAddOn.id}`}
                onChange={onVideoUrlChange}
                value={addOnVideoUrl}
                notes="(optional)"
              />{' '}
              <div className="relative top-5">
              <ErrorMessage show={errors?.videoUrl !== null}>
                {errors?.videoUrl}
              </ErrorMessage></div>
              <span className=" font-aw-sans inline text-xs text-aw-gray-300">
                ex: https://www.vimeo.com/123456789
              </span>
            </div>
            <div className="mt-4">
              <FieldLabel htmlFor={`desc-${currentAddOn.id}`}>
                Add-on description:{' '}
                <span className="ml-4 inline text-xs text-aw-gray-300">
                  (max 750 characters)
                </span>
              </FieldLabel>
              <ReactQuill
                theme="snow"
                value={description}
                onChange={onDescriptionChange}
                className="rounded-lg bg-white mt-4"
              />
              <ErrorMessage show={errors?.description !== null}>
                {errors?.description}
              </ErrorMessage>
            </div>
          </div>
          <div className="w-1/2 pl-10">
            <FieldLabel>
              Add-on image:
              <span className="ml-4 inline text-xs text-aw-gray-300">
                1360x800 recommended
              </span>
            </FieldLabel>
            <ImageUploadOrSelect
              imageType="Add on"
              setImage={handleSelectImage}
              currentImage={currentAddOn.image}
              targetEntryId={currentAddOn.id}
              tagsToAddToUploads={[builderImageTag, 'component']}
              imageTagToQuery="component"
              aspect="portrait"
            />
            <ErrorMessage show={errors?.image !== null}>
              {errors?.image}
            </ErrorMessage>
          </div>
        </div>
        <div
          className="mt-4 flex w-min cursor-pointer rounded-lg border py-2 px-4 text-aw-gray-700 hover:fill-red-400 hover:text-red-400 hover:border-red-400"
          onClick={() => setConfirmDeleteOpen(true)}
        >
          <DeleteIcon className="mr-3 fill-current" />
          <span className="font-aw-sans">Delete</span>
        </div>
      </div>
    </>
  );
}

export default GeneratorAddOnCard;
